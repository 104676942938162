export const formatDateTime = (dateString, locale = 'id-ID', options = {}) => {
    const dateTime = new Date(dateString);
  
    // Default options jika tidak diberikan
    const defaultOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
  
    // Gabungkan opsi bawaan dengan opsi pengguna
    const formatOptions = { ...defaultOptions, ...options };
  
    const formatted = new Intl.DateTimeFormat(locale, formatOptions).format(dateTime);

    // Ganti "pukul" atau pemisah lain dengan <br />
    return formatted.replaceAll('/', '-').replace(', ', '<br />');
  };