import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import axiosInstance from "../../../services/AxiosInstance";
import { error } from "ajv/dist/vocabularies/applicator/dependencies";
import { Navigate, useNavigate } from "react-router-dom";
import Pagination from "../Pagination";

import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const ItemType = "row";



const AddPaketSoal = () => {
  const navigate = useNavigate();
  const [isClearable, setIsClearable] = useState(false);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const [step, setStep] = useState("first");


  const [nullSel, setNullSel] = useState([
    { value: "-", label: "Select Option" },
  ]);
  const [showFilter, setShowFilter] = useState(false);

  const [elemenSoal, setElemenSoal] = useState([]);

  const [kelasOpt, setKelasOpt] = useState([]);
  const [selectedKelas, setSelectedKelas] = useState(null);

  const [active, setActive] = useState("pilihan-ganda");
  const [showBtnAdd, setShowBtnAdd] = useState(true);

  const [jurusanOpt, setJurusanOpt] = useState([]);
  const [selectedJurusan, setSelectedJurusan] = useState(null);

  const [typeOpt, setTypeOpt] = useState([
    { value: "test-online/usbk", label: "Test Online/USBK" },
    { value: "tryout", label: "Tryout" },
  ])

  const [selectedType, setSelectedType] = useState(null);

  const [jenjangOpt, setJenjangOpt] = useState([
    { value: "PAUD", label: "PAUD" },
    { value: "SD/MI", label: "SD/MI" },
    { value: "SMP/MTs", label: "SMP/MTs" },
    { value: "SMA/MA", label: "SMA/MA" },
    { value: "SMK/MAK", label: "SMK/MAK" },
  ]);
  const [selectedJenjang, setselectedJenjang] = useState(null);

  const [semesterOpt, setSemesterOpt] = useState([
    { value: "ganjil", label: "Ganjil" },
    { value: "genap", label: "Genap" },
  ])
  const [selectedSemester, setSelectedSemester] = useState(null);

  const [selectedMataPelajaran, setSelectedMataPelajaran] = useState(null);

  const [duration, setDuration] = useState(0);
  const [title, setTitle] = useState(null);
  const [indicator, setIndicator] = useState(null);
  const [arrMataPelajaran, setArrMataPelajaran] = useState([]);

  const [arrButirSoal, setArrButirSoal] = useState([]);

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const [searchKeyword, setSearchKeyword] = useState("");
  const [excludeSoalId, setExcludeSoalId] = useState([]);
  useEffect(() => {
    // loadMataPelajaran();
    loadKurikulum();
    loadJenjang();
  }, [])
  useEffect(() => {
    console.log("load soal :", currentPage);
    console.log("load excludeSoalId :", excludeSoalId);
    getListSoal();
  }, [currentPage, selectedKelas, selectedMataPelajaran, excludeSoalId]);
  const getListSoal = async () => {
    console.log("getListSoal:", selectedKelas);
    console.log("getListSoal:", selectedMataPelajaran);
    const res = await axiosInstance.get("soal?page=" + currentPage + "&per_page=" + perPage + "&kelas=" + (selectedKelas ?? "") + "&mata_pelajaran=" + (selectedMataPelajaran?.label ?? "") + "&materi=" + searchKeyword + "&exclude=" + excludeSoalId.join(","));

    if (res.data) {
      var soal = res.data.data;
      console.log("soal:", soal);
      var tmp = []
      for (var i = 0; i < soal.length; i++) {
        tmp.push({
          id: soal[i].id,
          judul: soal[i].detail.materi,
          kode_butir: soal[i].code,
          tipe_soal: getTypeName(soal[i].type),
          kelas: soal[i].detail.kelas,
          type_style: typeByJenisSoal(soal[i].type),
        },);
      }

      setArrButirSoal(tmp);
      setTotal(res.data.total);
      setCurrentPage(res.data.current_page);
    }
  };

  const typeByJenisSoal = (type) => {
    switch (type) {
      case "PG":
        return "pilihan-ganda";
      case "SA":
        return "sebab-akibat";
      case "BS":
        return "benar-salah";
      case "UR":
        return "uraian";
      case "PJ":
        return "penjodohan";
      case "MPG":
        return "pilihan-ganda";
      default:
        return "PG";
    }
  };

  const [mataPelajaranOpt, setMataPelajaranOpt] = useState([]);
  const loadMataPelajaran = async (jenisKelompok) => {
    var response = await axiosInstance.get("select/mata-pelajaran?jenis_kelompok_mata_pelajaran_id="+ jenisKelompok.value + "&kurikulum=" + selectedKurikulum.value);
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].id, label: data[i].label });
      }
      setMataPelajaranOpt(tmp);
    }
  };

  const handleSelectMataPelajaran = (e) => {
    if (e) {
      setSelectedMataPelajaran(e);
    }
  };

  const onPaging = (page) => {
    // getListSoal();
    setCurrentPage(page);
  }

  const [arrSelectedSoal, setArrSelectedSoal] = useState({});


  const [activeTabs, setActiveTabs] = useState([]); // Menggunakan array untuk menyimpan tab aktif
  const [formValues, setFormValues] = useState({
    PG: { jumlah_soal: 0, bobot: 0, point_benar: 0, point_salah: 0, point_kosong: 0 },
    MPG: { jumlah_soal: 0, bobot: 0, point_benar: 0, point_salah: 0, point_kosong: 0 },
    SA: { jumlah_soal: 0, bobot: 0, point_benar: 0, point_salah: 0, point_kosong: 0 },
    UR: { jumlah_soal: 0, bobot: 0, point_benar: 0, point_salah: 0, point_kosong: 0 },
    PJ: { jumlah_soal: 0, bobot: 0, point_benar: 0, point_salah: 0, point_kosong: 0 },
    BS: { jumlah_soal: 0, bobot: 0, point_benar: 0, point_salah: 0, point_kosong: 0 },
  });

  

  const [errors, setErrors] = useState({
    PG: {},
    MPG: {},
    SA: {},
    UR: {},
    PJ: {},
    BS: {},
  });

  useEffect(() => {
    console.log("activeTabs:", activeTabs);
    console.log("formValues:", formValues);
    formValuesRef.current = formValues;
  }, [activeTabs, formValues, errors]);
  
  const formValuesRef = useRef(formValues);
  
  const nullableField = ['point_salah', 'point_kosong'];

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;
    console.log("Title :", title);

    if(selectedType == null){
      isValid = false;
      newErrors['platform'] = "Platform is required";
    }
    if(title == null){
      isValid = false;
      newErrors['title'] = "Judul is required";
    }
    if(selectedJenjang == null){
      isValid = false;
      newErrors['jenjang'] = "Jenjang is required";
    }

    if(selectedMataPelajaran == null){
      isValid = false;
      newErrors['mapel'] = "Mata Pelajaran is required";
    }

    if(selectedKelas == null){
      isValid = false;
      newErrors['kelas'] = "Kelas is required";
    }

    if(selectedJurusan == null){
      isValid = false;
      newErrors['jurusan'] = "Jurusan is required";
    }

    if(selectedSemester == null){
      isValid = false;
      newErrors['semester'] = "Semester is required";
    }

    if(duration == 0){
      isValid = false;
      newErrors['duration'] = "Duration is required";
    }

    if(indicator == null){
      isValid = false;
      newErrors['indicator'] = "Indikator is required";
    }

    console.log("validate form tab :", activeTabs);
    if(activeTabs.length == 0){
      isValid = false;
      newErrors['tab'] = "Komposisi Soal is required";
    }

    activeTabs.forEach((tab) => {
      newErrors[tab] = {};
      var formValue = formValuesRef.current;
      console.log("tengah validate form :", tab, formValue[tab]);
      Object.keys(formValue[tab]).forEach((key) => {
        console.log("validate form :", key, formValue[tab], formValue[tab][key]);
        if(!nullableField.includes(key) && formValue[tab][key] == 0){
          isValid = false;
          newErrors[tab][key] = `${key} is required`;
        }
      });
    });

    setErrors(newErrors);
    return {
      isValid: isValid,
      errors: newErrors,
    };
  };

  const getTypeName = (type) => {
    if (type == "PG") {
      return "Pilihan Ganda";
    } else if (type == "MPG") {
      return "Multiple Pilihan Ganda";
    } else if (type == "BS") {
      return "Benar Salah";
    } else if (type == "UR") {
      return "Uraian";
    } else if (type == "PJ") {
      return "Penjodohan";
    } else if (type == "SA") {
      return "Sebab Akibat";
    }
  };

  const handleActive = (tab) => {
    setActiveTabs((prevActiveTabs) =>
      prevActiveTabs.includes(tab)
        ? prevActiveTabs.filter((t) => t !== tab) // Jika sudah aktif, nonaktifkan
        : [...prevActiveTabs, tab] // Jika belum aktif, tambahkan ke tab aktif
    );
  };

  const handleInput = (e, tab, key) => {
    const { value } = e.target;
    console.log("handleInput", tab, key, value);
    // setFormValues({
    //   ...formValues,
    //   [tab]: {
    //     ...formValues[tab],
    //     [key]: value,
    //   },
    // });
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [tab]: {
        ...prevFormValues[tab],
        [key]: value,
      },
    }));
    
  };

  const generateScore = () => {
    const score = {
      PG: formValues.PG,
      MPG: formValues.MPG,
      SA: formValues.SA,
      UR: formValues.UR,
      PJ: formValues.PJ,
      BS: formValues.BS,
    };
    console.log("Generated score:", score);
    return score;
  };

  const handleSelectJenjang = (e) => {
    if (e) {
      if(selectedKurikulum == null){
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Pilih Kurikulum terlebih dahulu",
        });
        return;
      }
      setSelectedKelas(null);
      // if (e.value == "PAUD") {
      //   setKelasOpt([{ value: "0", label: "0" }]);
      // } else if (e.value == "SD/MI") {
      //   setKelasOpt([
      //     { value: "I", label: "I" },
      //     { value: "II", label: "II" },
      //     { value: "III", label: "III" },
      //     { value: "IV", label: "IV" },
      //     { value: "V", label: "V" },
      //     { value: "VI", label: "VI" },
      //   ]);
      // } else if (e.value == "SMP/MTs") {
      //   setKelasOpt([
      //     { value: "VII", label: "VII" },
      //     { value: "VIII", label: "VIII" },
      //     { value: "IX", label: "IX" },
      //   ]);
      // } else if (e.value == "SMA/MA") {
      //   setKelasOpt([
      //     { value: "X", label: "X" },
      //     { value: "XI", label: "XI" },
      //     { value: "XII", label: "XII" },
      //   ]);
      // } else if (e.value == "SMK/MAK") {
      //   setKelasOpt([
      //     { value: "X", label: "X" },
      //     { value: "XI", label: "XI" },
      //     { value: "XII", label: "XII" },
      //   ]);
      // }
      setselectedJenjang(e.value);
      getJurusan(e.value);
      getKelas(e.value);
      loadKelompokMataPelajaran(e);
    }
  };

  const [kurikulum, setKurikulum] = useState([]);
  const [selectedKurikulum, setSelectedKurikulum] = useState(null);

  const loadKurikulum = async () => {
    var response = await axiosInstance.get("/select/kurikulum");
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].value, label: data[i].label });
      }
      setKurikulum(tmp);
    }
  };

  const handleSelectKurikulum = (e) => {
    if (e) {
      setSelectedKurikulum(e);
    }
  };


  const loadJenjang = async () => {
    var response = await axiosInstance.get("select/jenjang");
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      console.log("loadJenjang data:", data);
      for (var i = 0; i < data.length; i++) {
        tmp.push({id: data[i].id, value: data[i].jenjang, label: data[i].jenjang });
      }
      setJenjangOpt(tmp);
    }
  }

  const [kelompokMataPelajaranOpt, setKelompokMataPelajaranOpt] = useState([]);
  const [selectedKelompokMataPelajaran, setSelectedKelompokMataPelajaran] = useState(null);

  const loadKelompokMataPelajaran = async (jenjang) => {
    console.log("loadKelompokMataPelajaran : {}", jenjang);
    var response = await axiosInstance.get("select/kelompok-mata-pelajaran?jenjang_id="+ jenjang.id + "&kurikulum=" + selectedKurikulum.value);
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      console.log("loadKelompokMataPelajaran data:", data);
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].id, label: data[i].label });
      }
      setKelompokMataPelajaranOpt(tmp);
    }
  };

  const handleSelectKelompokMataPelajaran = (e) => {
    if (e) {
      setSelectedKelompokMataPelajaran(e);
      loadJenisKelompokMataPelajaran(e);
    }
  };

  const [jenisKelompokMataPelajaranOpt, setJenisKelompokMataPelajaranOpt] = useState([]);
  const [selectedJenisKelompokMataPelajaran, setSelectedJenisKelompokMataPelajaran] = useState(null);

  const loadJenisKelompokMataPelajaran = async (kelompok) => {
    console.log("loadJenisKelompokMataPelajaran : {}", kelompok);
    var response = await axiosInstance.get("select/jenis-kelompok-mata-pelajaran?kelompok_mata_pelajaran_id="+ kelompok.value + "&kurikulum=" + selectedKurikulum.value);
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      console.log("loadJenisKelompokMataPelajaran data:", data);
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].id, label: data[i].label });
      }
      setJenisKelompokMataPelajaranOpt(tmp);
    }
  }

  const handleSelectJenisKelompokMataPelajaran = (e) => {
    if (e) {
      setSelectedJenisKelompokMataPelajaran(e);
      loadMataPelajaran(e);
    }
  };
  
  const handleSelectedSoal = (e) => {
    setExcludeSoalId([...excludeSoalId, e.id]);
    console.log("handleSelectedSoal", e);
    console.log("arrSelectedSoal", arrSelectedSoal, JSON.stringify(arrSelectedSoal));
    console.log("undefined , ", arrSelectedSoal[e.type_style] == undefined)
      // setArrSelectedSoal([...arrSelectedSoal, e]);
      if (arrSelectedSoal[e.type_style] == undefined) {
        setArrSelectedSoal({ ...arrSelectedSoal, [e.type_style]: [e] });
        console.log("arrSelectedSoal", arrSelectedSoal);

        return;
      }
      const exists = arrSelectedSoal[e.type_style].some((item) => item.id === e.id);


      if (exists) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Soal sudah dipilih",
        })
      } else {
        setArrSelectedSoal((arrSelectedSoal) => ({
          ...arrSelectedSoal,
          [e.type_style]: [
            ...arrSelectedSoal[e.type_style],
            e
          ],
        }));
        // setArrSelectedSoal({
        //   ...arrSelectedSoal[e.type_style],
        //   [e.type_style]: tmp
        // });

        // console.log("arrSelectedSoal", arrSelectedSoal);
        // setArrSelectedSoal([e.tipe_soal]: [...arrSelectedSoal[e.tipe_soal], e]);
      }
  }

  const parseAllValuesToFloat = (obj) => {
    const result = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        result[key] = Object.fromEntries(
          Object.entries(obj[key]).map(([k, v]) => [k, parseFloat(v)])
        );
      }
    }
    return result;
  };

  const handleNext = () => {
    var validate = validateForm();
    console.log("validate:", validate);
    if (validate.isValid) {
      const score = {
        PG: formValues.PG,
        MPG: formValues.MPG,
        SA: formValues.SA,
        UR: formValues.UR,
        PJ: formValues.PJ,
        BS: formValues.BS,
      };
      
      console.log("Generated score:", score);
      var floatScore = parseAllValuesToFloat(score);
      console.log("floatScore:", floatScore);
      setElemenSoal(floatScore);
      setStep("next");
    } else {
      console.log("Form validation failed.");
      var text = '';
      for (const [key, value] of Object.entries(validate.errors)) {
        console.log(key, value, typeof value, typeof value === 'object' ? Object.keys(value).length !== 0 : 'bukan object');
        if (typeof value === 'object') {
          if (Object.keys(value).length !== 0) {
            text += "<b>" + getTypeName(key) + "</b><br/>";
            for (const [k, v] of Object.entries(value)) {
              console.log(k, v);
              text += v + "<br/>";
            }
          }
        }else{
          text += value + "<br/>";
        }
      }
      Swal.fire({
        title: "Oops !",
        html: text,
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oke !",
      });
    }
  };

  const handleFormChange = (value, type) => {
    if (type == "type") {
      setSelectedType(value);
    }
    if (type == "title") {
      setTitle(value);
    }

    if (type == "matapelajaran") {
      setSelectedMataPelajaran(value);
    }

    if (type == "kelas") {
      setSelectedKelas(value);
    }

    if (type == "jurusan") {
      setSelectedJurusan(value);
    }

    if (type == "semester") {
      setSelectedSemester(value);
    }

    if (type == "duration") {
      setDuration(value);
    }

    if (type == "indicator") {
      setIndicator(value);
    }
  };

  function capitalizeWords(text) {
    return text
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const getJurusan = async (jenjang) => {
    var response = await axiosInstance.get("/select/jurusan?jenjang=" + jenjang);
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].value, label: data[i].text });
      }
      setJurusanOpt(tmp);
    }
  };

  const getKelas = async (jenjang) => {
    var response = await axiosInstance.get("/select/kelas?jenjang=" + jenjang);
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].value, label: data[i].text });
      }
      setKelasOpt(tmp);
    }
  };

  const handleSave = async () => {
    if (arrSelectedSoal.length == 0) {
      Swal.fire({
        title: "Oops !",
        text: "Pilih minimal 1 soal",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oke !",
      });
      return;
    }

    console.log('arrSelectedSoal', arrSelectedSoal)
    console.log('order', order)
    var no_urut = 1;
    var tmpSoal = {};
    order.map((item, index) => {
      console.log(item, index)
      arrSelectedSoal[item].map((item2, index2) => {
        console.log(no_urut, item2, index2)
        tmpSoal[no_urut] = item2.id;
        no_urut++;
      })
    })

    console.log('tmpSoal', tmpSoal)

    var params = {
      "type": selectedType,
      "detail": {
          "judul": title,
          "kurikulum": selectedKurikulum.value,
          "jenjang": selectedJenjang.split("/")[0],
          "kelas": selectedKelas,
          "jurusan": selectedJurusan,
          "semester": selectedSemester,
          "kelompok_mata_pelajaran": selectedKelompokMataPelajaran.label,
          "jenis_kelompok_mata_pelajaran": selectedJenisKelompokMataPelajaran.label,
          "mata_pelajaran": selectedMataPelajaran.label,
          "durasi": duration * 60,
          "indikator": indicator,
          "image": null
      },
      "score": elemenSoal,
      "soal_id": tmpSoal
      // "soal_id": arrSelectedSoal.map((soal) => soal.id),
      // "soal_id": arrSelectedSoal.reduce((acc, soal, index) => {
      //   acc[index + 1] = soal.id;
      //   return acc;
      // }, {})
    }
    
    console.log("params:", params);
    // return;
    var res = await axiosInstance.post("/paket-soal", params);
    if (res.data.success) {
      Swal.fire({
        title: "Success !",
        text: "Paket Soal Berhasil",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oke !",
      })
      console.log("res:", res.data);
      navigate("/paket-soal");
    }else{
      Swal.fire({
        title: "Oops !",
        text: res.data.message,
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oke !",
      })
    }
  }

  const goTo = (action, contact) => {
    console.log("goTo", contact);
    // navigate(`/kartu-soal/${action}/${contact.type_style}?id=${contact.id}`);
    window.open(`/kartu-soal/${action}/${contact.type_style}?id=${contact.id}`, '_blank');
  }

  const handleRemove = (index, soal) => {
    setArrSelectedSoal((prevItems) => prevItems.filter(item => item !== soal));
  }

  const moveRow = (key, dragIndex, hoverIndex) => {
    setArrSelectedSoal((prevState) => {
      const updatedSoal = [...prevState[key]];
      const [movedItem] = updatedSoal.splice(dragIndex, 1);
      updatedSoal.splice(hoverIndex, 0, movedItem);

      return { ...prevState, [key]: updatedSoal };
    });
  };

  const DraggableRow = ({ item, index,categoryKey, moveRow }) => {
    const ref = React.useRef(null);
  
    const [, drop] = useDrop({
      accept: "row",
      hover: (draggedItem) => {
        if (draggedItem.index !== index) {
          moveRow(categoryKey, draggedItem.index, index);
          draggedItem.index = index;
        }
      },
    });
  
    const [{ isDragging }, drag] = useDrag({
      type: "row",
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
  
    drag(drop(ref));
  
    return (
      <tr
        ref={ref}
        style={{
          opacity: isDragging ? 0.5 : 1,
          cursor: "move",
        }}
      >
        <td>{index + 1}</td>
        <td>{item.kode_butir}</td>
        <td>{item.judul}</td>
        <td>
          <button className="btn btn-danger btn-sm">Delete</button>
        </td>
      </tr>
    );
  };

  const formatText = (text) => {
    return text
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const [order, setOrder] = useState();

  useEffect(() => {
    setOrder(Object.keys(arrSelectedSoal));
  }, [arrSelectedSoal]);
  // Fungsi untuk memindahkan kategori
  const moveCategory = (dragIndex, hoverIndex) => {
    setOrder((prevOrder) => {
      const updatedOrder = [...prevOrder];
      const [movedKey] = updatedOrder.splice(dragIndex, 1);
      updatedOrder.splice(hoverIndex, 0, movedKey);
      return updatedOrder;
    });
  };

//   return (
//     <DndProvider backend={HTML5Backend}>
//       <div>
//         {order.map((key, index) => (
//           <DraggableCategory
//             key={key}
//             categoryKey={key}
//             category={arrSelectedSoal[key]}
//             index={index}
//             moveCategory={moveCategory}
//           />
//         ))}
//       </div>
//     </DndProvider>
//   );
// };

const deleteRow = (categoryKey, itemId) => {
  setArrSelectedSoal((prevState) => ({
    ...prevState,
    [categoryKey]: prevState[categoryKey].filter((item) => item.id !== itemId),
  }));
};
const DraggableCategory = ({ categoryKey, category, index, moveCategory, deleteRow }) => {
  const ref = React.useRef(null);

  // Drop target
  const [, drop] = useDrop({
    accept: "category",
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveCategory(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  // Drag source
  const [{ isDragging }, drag] = useDrag({
    type: "category",
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      style={{
        opacity: isDragging ? 0.5 : 1,
        marginBottom: "20px",
        border: "1px solid #ccc",
        padding: "10px",
        backgroundColor: "#f9f9f9",
        cursor: "move",
      }}
    >
      <h4>{formatText(categoryKey)}</h4>
      <table className="table table-responsive table-bordered w-100">
        <thead>
          <tr>
            <th>No</th>
            {/* <th>Bentuk Soal</th> */}
            <th>Kode Soal</th>
            <th>Judul Soal</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {category.map((item, idx) => (
            <DraggableRow
              key={idx}
              categoryKey={categoryKey}
              item={item}
              index={idx}
              moveRow={moveRow}
            />
            // <tr key={item.id}>
            //   <td>{idx + 1}</td>
            //   {/* <td>{item.bentukSoal}</td> */}
            //   <td>{item.kode_butir}</td>
            //   <td>{item.judul}</td>
            //   <td>
            //     <button
            //       className="btn btn-danger btn-sm"
            //       onClick={() => deleteRow(categoryKey, item.id)}
            //     >
            //       Hapus
            //     </button>
            //   </td>
            // </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
  const handleCariButir = (e) => {
    console.log("handleCariButir");
    if (currentPage === 1) {
      getListSoal();  
    }
    setCurrentPage(1);
    
  };
  
  return (
    <>
      {step == "first" ? (
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h4>Tambah Paket Soal</h4>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <h5 className="text-primary">
                        Judul <span className="text-danger">*</span>
                      </h5>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Masukkan Judul Paket"
                        onChange={(e) => handleFormChange(e.target.value, "title")}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <h5 className="text-primary">
                        Kurikulum <span className="text-danger">*</span>
                      </h5>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={nullSel[0]}
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        name="color"
                        options={kurikulum}
                        onChange={handleSelectKurikulum}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <h5 className="text-primary">
                        Jenjang <span className="text-danger">*</span>
                      </h5>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={nullSel[0]}
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        name="color"
                        options={jenjangOpt}
                        onChange={handleSelectJenjang}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <h5 className="text-primary">
                        Kelompok Mata Pelajaran <span className="text-danger">*</span>
                      </h5>
                      <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={kelompokMataPelajaranOpt}
                          onChange={handleSelectKelompokMataPelajaran}
                          value={selectedKelompokMataPelajaran}
                        />
                    </div>
                    <div className="form-group mt-3">
                      <h5 className="text-primary">
                        Jenis Kelompok Mata Pelajaran <span className="text-danger">*</span>
                      </h5>
                      <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={jenisKelompokMataPelajaranOpt}
                          onChange={handleSelectJenisKelompokMataPelajaran}
                          value={selectedJenisKelompokMataPelajaran}
                        />
                    </div>
                    <div className="form-group mt-3">
                      <h5 className="text-primary">
                        Mata Pelajaran <span className="text-danger">*</span>
                      </h5>
                      {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Masukkan Mata Pelajaran"
                        onChange={(e) => handleFormChange(e.target.value, "matapelajaran")}
                      /> */}
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={mataPelajaranOpt[0]}
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        name="color"
                        options={mataPelajaranOpt}
                        onChange={(e) => handleFormChange(e, "matapelajaran")}
                      />
                    </div>
                    
                  </div>

                  <div className="col-sm-6">
                  <div className="form-group">
                      <h5 className="text-primary">
                        Kelas <span className="text-danger">*</span>
                      </h5>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={nullSel[0]}
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        name="color"
                        options={kelasOpt}
                        onChange={(e) => handleFormChange(e.value, "kelas")}
                      />
                    </div>
                    <div className="form-group  mt-3">
                      <h5 className="text-primary">
                        Jurusan <span className="text-danger">*</span>
                      </h5>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        name="color"
                        options={jurusanOpt}
                        onChange={(e) => handleFormChange(e.value, "jurusan")}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <h5 className="text-primary">
                        Semester <span className="text-danger">*</span>
                      </h5>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        name="color"
                        options={semesterOpt}
                        onChange={(e) => handleFormChange(e.value, "semester")}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <h5 className="text-primary">
                        Durasi (Menit)<span className="text-danger">*</span>
                      </h5>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Masukkan durasi"
                        onChange={(e) => handleFormChange(e.target.value, "duration")}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <h5 className="text-primary">
                        Platform <span className="text-danger">*</span>
                      </h5>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={nullSel[0]}
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        name="color"
                        options={typeOpt}
                        onChange={(e) => handleFormChange(e.value, "type")}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group mt-3">
                      <h5 className="text-primary">
                        Indikator <span className="text-danger">*</span>
                      </h5>
                      <textarea
                        name=""
                        id=""
                        className="form-control"
                        placeholder="Masukkan indikator paket soal"
                        onChange={(e) => handleFormChange(e.target.value, "indicator")}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-sm-12 mt-4">
                    <div className="row">
                      <div className="col-5">
                        {" "}
                        <hr />
                      </div>
                      <div className="col-2 text-center">
                        <h5 className="text-primary mt-1">
                          Komposisi Paket Soal
                        </h5>
                      </div>
                      <div className="col-5">
                        {" "}
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 d-flex justify-content-between flex-wrap mt-3">
                  <ul className="nav nav-tabs dzm-tabs" id="myTab" role="tablist">
                    {["PG", "MPG", "SA", "UR", "PJ", "BS"].map((tab, idx) => (
                      <li key={idx} className="nav-item">
                        <button
                          onClick={() => handleActive(tab)}
                          className={`nav-link m-1 btn-outline-primary btn-sm ${activeTabs.includes(tab) ? "active" : null}`}
                          type="button"
                        >
                          {getTypeName(tab)}
                        </button>
                      </li>
                    ))}
                  </ul>
                  </div>
                  <div className="col-sm-12 mt-2">
        {activeTabs.map((tab, idx) => (
          <div key={idx} className="row mt-3">
            <div className="col">
              <div className="form-group">
                <h5 className={`text-primary text-bold`}>
                  Jumlah Soal ({tab.toUpperCase()})
                </h5>
                <input
                  type="text"
                  className={`form-control border-primary`}
                  value={formValues[tab].jumlah_soal}
                  onChange={(e) => handleInput(e, tab, "jumlah_soal")}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <h5 className={`text-primary text-bold`}>
                  Bobot ({tab.toUpperCase()})
                </h5>
                <input
                  type="text"
                  className={`form-control border-primary`}
                  value={formValues[tab].bobot}
                  onChange={(e) => handleInput(e, tab, "bobot")}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <h5 className={`text-primary text-bold`}>
                  Poin Benar ({tab.toUpperCase()})
                </h5>
                <input
                  type="text"
                  className={`form-control border-primary`}
                  value={formValues[tab].point_benar}
                  onChange={(e) => handleInput(e, tab, "point_benar")}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <h5 className={`text-primary text-bold`}>
                  Poin Salah ({tab.toUpperCase()})
                </h5>
                <input
                  type="text"
                  className={`form-control border-primary`}
                  value={formValues[tab].point_salah}
                  onChange={(e) => handleInput(e, tab, "point_salah")}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <h5 className={`text-primary text-bold`}>
                  Poin Tidak Jawab ({tab.toUpperCase()})
                </h5>
                <input
                  type="text"
                  className={`form-control border-primary`}
                  value={formValues[tab].point_kosong}
                  disabled={true}
                  onChange={(e) => handleInput(e, tab, "point_kosong")}
                />
              </div>
            </div>
          </div>
        ))}
        <div className="row mt-5">
                      <div className="col-12 text-end">
                        <button
                          type="button"
                          onClick={handleNext}
                          className="btn btn-primary mt-3"
                        >
                          Selanjutnya <i className="fas fa-arrow-right"></i>
                        </button>
                      </div>
                    </div>

      </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-sm-8">
            <div className="row">
              <div className="col-12">
                <div className="card card-c-left">
                  <div className="card-header">
                    <h5>Paket Soal</h5>
                  </div>
                  <div className="card-body table-responsive">
                  {order && <DndProvider backend={HTML5Backend}>
                    <div>
                      {order.map((key, index) => (
                        <DraggableCategory
                          key={key}
                          categoryKey={key}
                          category={arrSelectedSoal[key]}
                          index={index}
                          moveCategory={moveCategory}
                          deleteRow={deleteRow}
                        />
                      ))}
                    </div>
                  </DndProvider>}
                    {/* {Object.keys(arrSelectedSoal).map((key, idx) => (
                      <>
                      <h4>{key}</h4>
                      <DndProvider backend={HTML5Backend}>
                        <table className="table table-responsive table-bordered w-100">
                          <thead>
                            <tr>
                              <th>No</th>
                              <th>Bentuk Soal</th>
                              <th>Kode Soal</th>
                              <th>Judul Soal</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {arrSelectedSoal[key].map((item, index) => (
                              <DraggableRow
                                key={index}
                                item={item}
                                index={index}
                                moveRow={(dragIndex, hoverIndex) => moveRow(key, dragIndex, hoverIndex)}
                              />
                            ))}
                          </tbody>
                        </table>
                      </DndProvider>
                      </>
                    ))} */}

                    <div className="col-sm-12 text-end">
                      <button className="btn btn-sm m-1 btn-primary" onClick={handleSave}>
                        Simpan
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="row">
              {/* <div className="col-12">
                <div className="card">
                  <div
                    onClick={() => setShowFilter(!showFilter)}
                    className="card-header card-header-komposisi d-flex justify-content-between flex-wrap"
                  >
                    <div>
                      <div className="card-title">
                        {" "}
                        <i className="fas fa-filter"></i> Filter
                      </div>
                    </div>
                    <div>
                      <span>
                        <i
                          className={`fas fa-chevron-${
                            showFilter ? "up" : "down"
                          }`}
                        ></i>
                      </span>
                    </div>
                  </div>
                  {showFilter ? (
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <h5 className="text-primary">
                              Jenjang <span className="text-danger">*</span>
                            </h5>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              defaultValue={jenjang[0]}
                              isDisabled={isDisabled}
                              isLoading={isLoading}
                              isClearable={isClearable}
                              isRtl={isRtl}
                              isSearchable={isSearchable}
                              name="color"
                              options={jenjang}
                              onChange=""
                            />
                          </div>
                          <div className="form-group mt-3">
                            <h5 className="text-primary">
                              Mata Pelajaran{" "}
                              <span className="text-danger">*</span>
                            </h5>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              defaultValue={nullSel[0]}
                              isDisabled={isDisabled}
                              isLoading={isLoading}
                              isClearable={isClearable}
                              isRtl={isRtl}
                              isSearchable={isSearchable}
                              name="color"
                              options={nullSel}
                              onChange=""
                            />
                          </div>
                          <div className="form-group mt-3">
                            <h5 className="text-primary">
                              Kelas <span className="text-danger">*</span>
                            </h5>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              defaultValue={nullSel[0]}
                              isDisabled={isDisabled}
                              isLoading={isLoading}
                              isClearable={isClearable}
                              isRtl={isRtl}
                              isSearchable={isSearchable}
                              name="color"
                              options={nullSel}
                              onChange=""
                            />
                          </div>
                        </div>

                        <div className="col-sm-12">
                          <div className="form-group">
                            <h5 className="text-primary">
                              Jurusan <span className="text-danger">*</span>
                            </h5>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              defaultValue={nullSel[0]}
                              isDisabled={isDisabled}
                              isLoading={isLoading}
                              isClearable={isClearable}
                              isRtl={isRtl}
                              isSearchable={isSearchable}
                              name="color"
                              options={nullSel}
                              onChange=""
                            />
                          </div>
                          <div className="form-group mt-3">
                            <h5 className="text-primary">
                              Semester <span className="text-danger">*</span>
                            </h5>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              defaultValue={nullSel[0]}
                              isDisabled={isDisabled}
                              isLoading={isLoading}
                              isClearable={isClearable}
                              isRtl={isRtl}
                              isSearchable={isSearchable}
                              name="color"
                              options={nullSel}
                              onChange=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div> */}
              <div className="col-12">
                <div className="card  card-sel-soal">
                  <div className="card-header">
                    <div className="row">
                     <div className="col-10">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Cari nama butir atau kode butir"
                        onChange={(e) => setSearchKeyword(e.target.value)}
                      />
                     </div>
                     <div className="col-1">
                      <button className="btn btn-sm btn-primary" onClick={() => handleCariButir()}>
                          Cari
                        </button>
                     </div>
                    </div>
                    
                  </div>
                  <div className="card-body p-0 m-3" style={{ "maxHeight": '500px', "overflow": "auto" }}>
                    <div className="row">
                      {arrButirSoal.map((v, i) => (
                        <div className="col-12 pb-0 mb-0">
                          <>
                            <div className="mt-2 rounded pb-0 card-custom border">
                              <div className="card-header row">
                                <div className="col-9">
                                  <h5>{v.judul}</h5>
                                  <p>{v.kode_butir}</p>
                                </div>
                                <div className="col-3">
                                  <button
                                    type="button"
                                    onClick={() => handleSelectedSoal(v)}
                                    className="btn mb-1 btn-outline-primary btn-sm rounded-circle"
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                              <div className="card-body">
                                <div className="row mb-0 mt-3">
                                  <div className="col-4"> Tipe Soal</div>
                                  <div className="col-8"> : {v.tipe_soal}</div>
                                </div>

                                <div className="row">
                                  <div className="col-4"> Kelas</div>
                                  <div className="col-8"> : {v.kelas}</div>
                                </div>
                              </div>
                            </div>
                          </>
                        </div>
                      ))}
                    </div>                    
                  </div>
                  <div className="card-footer">
                  <Pagination totalData={total} dataPerPage={perPage} onChange={onPaging} visiblePageNumbers={3} showEllipsis={false}  />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddPaketSoal;
