import React from 'react';
import './GlobalLoader.css';
const GlobalLoader = ({ loading }) => {
  if (!loading) return null;

  return (
    <div className="global-loader">
      <div className="d-flex justify-content-center align-items-center vh-100 bg-light position-fixed top-0 start-0 w-100">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default GlobalLoader;
