import React, { useEffect, useRef, useState } from "react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
import { isEqual } from "../../../../utils";
import axiosInstance from "../../../../services/AxiosInstance";
import SMEditor from "../../Editor";

function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const file = await loader.file;
          console.log('loader file : ', file);
          const response = await axiosInstance.request({
            method: "POST",
            url: `media/upload`,
            data: {
              file: file
            },
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          console.log(response);
          resolve({
            default: `${response.data.url}`
          });
        } catch (error) {
          reject("Hello");
        }
      });
    },
    abort: () => {}
  };
}
function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    console.log("loader : ", loader);
    return uploadAdapter(loader);
  };
}
const Penjodohan = (props) => {
  const [soal, setSoal] = useState(null);
  const [pasangan, setPasangan] = useState(null);
  const [pengecoh, setPengecoh] = useState(null);

  const prevSoalValue = useRef();
  useEffect(() => {
    if (props.defaultValue != null) {
      if(!isEqual(prevSoalValue.current, soal)){
        prevSoalValue.current = soal;
        setSoal(props.defaultValue.pertanyaan);
        setPasangan(props.defaultValue.pasangan);
        setPengecoh(props.defaultValue.pengecoh);
      }
    }
  }, [props.defaultValue])
  const handleSaveButton = (redirect) => {
    var params = {
      "pertanyaan": soal,
      "pasangan": pasangan,
      "pengecoh": pengecoh

    };
    props.onSaveButton(params, redirect)

    if (redirect) {
      setSoal("");
      setPasangan("");
      setPengecoh("");
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <h5 className="text text-primary">
            Pernyataan <span className="text-danger">*</span>
          </h5>
          <div className="col-xl-12 mt-2">
            <SMEditor
              placeholder="Tulis Pernyataan disini"
              data={soal}
              disabled={props.isDisabled}
              onChange={(event, editor) => {
                const data = editor.getData();
                setSoal(data);
              }}
              onBlur={(event, editor) => {}}
            />
          </div>
        </div>
      </div>
      <div className="col-sm-12">
        <hr />
      </div>

      <div className="row mt-4">
        <div className="col-sm-6">
          <h5 className="text text-primary">
            Pasangan <span className="text-danger">*</span>
          </h5>
          <div className="col-xl-12 mt-2">
            <input
              type="text"
              className="form-control"
              placeholder="Masukkan pasangan"
              value={pasangan}
              onChange={(e) => setPasangan(e.target.value)}
              disabled={props.isDisabled}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <h5 className="text text-primary">Pengecoh</h5>
          <div className="col-xl-12 mt-2">
            <input
              type="text"
              className="form-control"
              placeholder="Masukkan pengecoh"
              value={pengecoh}
              onChange={(e) => setPengecoh(e.target.value)}
              disabled={props.isDisabled}
            />
          </div>
        </div>
      </div>
      {props.isDisabled ? "" : (
      <div className="row mt-3 mb-4">
        <div className="col-sm-12 text-end">
          {/* <button className="btn btn-sm m-1 btn-outline-primary">
            Simpan Draft
          </button> */}
          {/* <button className="btn btn-sm m-1 btn-primary" onClick={handleSaveButton}>Simpan</button> */}
          <button className="btn btn-sm m-1 btn-danger" onClick={() => handleSaveButton(false)}>Simpan & Keluar</button>
          <button className="btn btn-sm m-1 btn-primary" onClick={() => handleSaveButton(true)}>Simpan & Buat Baru</button>
        </div>
      </div>
      )}
    </>
  );
};

export default Penjodohan;
