import React, { useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Dropdown } from "react-bootstrap";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../services/AxiosInstance";
import Swal from "sweetalert2";
import { isEqual } from "../../../utils";
// import axios from "axios";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import axiosBahanAjar from "../../../services/axiosBahanAjar";
import {
  ClassicEditor
} from 'ckeditor5';
function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise(async (resolve, reject) => {
          try {
            const file = await loader.file;
            console.log('loader file : ', file);
            const response = await axiosInstance.request({
              method: "POST",
              url: `media/upload`,
              data: {
                file: file
              },
              headers: {
                "Content-Type": "multipart/form-data"
              }
            });
            console.log(response);
            resolve({
              default: `${response.data.url}`
            });
          } catch (error) {
            reject("Hello");
          }
        });
      },
      abort: () => {}
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      console.log("loader : ", loader);
      return uploadAdapter(loader);
    };
  }

const AddBahanAjar = () => {
  const { action, type } = useParams();
  const navigate = useNavigate();
  const [dataDetail, setDataDetail] = useState(null);
  const [mediaType, setMediaType] = useState([
    { value: "video", label: "Video" },
    { value: "pdf", label: "PDF" },
  ]);

  const [levelOpt, setLevelOpt] = useState([
    { value: "B1", label: "B1" },
    { value: "B2", label: "B2" },
    { value: "B3", label: "B3" },
    { value: "B4", label: "B4" },
    { value: "B5", label: "B5" },
  ]);

  useEffect(() => {
    loadJenjang();
    loadKurikulum();
    // loadMataPelajaran();
    if (action == "detail") {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let id = params.get('id');
      loadDetailButirSoal(id);
      setIsDisabled(true);
    }
    if (action == "update") {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let id = params.get('id');
      loadDetailButirSoal(id);
    }
  }, [action]);
  
  const loadDetailButirSoal = async (id) => {
    try {
      const response = await axiosInstance.get(`soal/${id}?with_jawaban=true`);
      var data = response.data.data;
      console.log("data:", data);
      setDataDetail(data);
      // setSelectedKelas(data.kelas);
      // setSelectedJurusan(data.jurusan);
      // setSelectedTingkatKesulitan(data.tingkat_kesulitan);
      // setSelectedSemester(data.semester);
      // setSelectedMataPelajaran(data.mata_pelajaran);
      // setTitle(data.title);
      // setDescription(data.description);
    } catch (error) {
      console.log(error);
    }
  };

  const [selectedMediaType, setSelectedMediaType] = useState({ value: "video", label: "Video" });
  const [selectedLevel, setSelectedLevel] = useState({ value: "B1", label: "B1" });
  const [selectedJenjang, setselectedJenjang] = useState(null);
  const [selectedKelas, setSelectedKelas] = useState(null);
  const [selectedJurusan, setSelectedJurusan] = useState(null);
  const [selectedTingkatKesulitan, setSelectedTingkatKesulitan] = useState(null);
  const [selectedSemester, setSelectedSemester] = useState(null);
  const [selectedMataPelajaran, setSelectedMataPelajaran] = useState(null);
  const [title, setTitle] = useState(null);
  const [indicator, setIndicator] = useState(null);
  const [description, setDescription] = useState(null);
  const [defaultValue, setDefaultValue] = useState(null);
  const [mediaUrl, setMediaUrl] = useState(null);

  const [isClearable, setIsClearable] = useState(false);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);

  const [colourOptions, setColourOptions] = useState([
    { value: "red", label: "Red" },
    { value: "blue", label: "Blue" },
    { value: "green", label: "Green" },
  ]);

  const [jenjang, setJenjang] = useState([
    // { value: "PAUD", label: "PAUD" },
    // { value: "SD/MI", label: "SD/MI" },
    // { value: "SMP/MTs", label: "SMP/MTs" },
    // { value: "SMA/MA", label: "SMA/MA" },
    // { value: "SMK/MAK", label: "SMK/MAK" },
  ]);

  const loadJenjang = async () => {
    var response = await axiosInstance.get("select/jenjang");
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      console.log("loadJenjang data:", data);
      for (var i = 0; i < data.length; i++) {
        tmp.push({id: data[i].id, value: data[i].id, label: data[i].jenjang });
      }
      setJenjang(tmp);
    }
  }

  const [kurikulum, setKurikulum] = useState([]);
  const [selectedKurikulum, setSelectedKurikulum] = useState(null);

  const loadKurikulum = async () => {
    var response = await axiosInstance.get("/select/kurikulum");
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].value, label: data[i].label });
      }
      setKurikulum(tmp);
    }
  };

  const loadKelas = async (jenjang) => {
    var response = await axiosInstance.get("/select/kelas?jenjang=" + jenjang);
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].id, label: data[i].text });
      }
      setKelas(tmp);
    }
  };

  const [kelompokMataPelajaranOpt, setKelompokMataPelajaranOpt] = useState([]);
  const [selectedKelompokMataPelajaran, setSelectedKelompokMataPelajaran] = useState(null);

  const loadKelompokMataPelajaran = async (jenjang) => {
    console.log("loadKelompokMataPelajaran : {}", jenjang);
    var response = await axiosInstance.get("select/kelompok-mata-pelajaran?jenjang_id="+ jenjang.id + "&kurikulum=" + selectedKurikulum.value);
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      console.log("loadKelompokMataPelajaran data:", data);
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].id, label: data[i].label });
      }
      setKelompokMataPelajaranOpt(tmp);
    }
  };

  const handleSelectKelompokMataPelajaran = (e) => {
    if (e) {
      setSelectedKelompokMataPelajaran(e);
      loadJenisKelompokMataPelajaran(e);
    }
  };

  const [jenisKelompokMataPelajaranOpt, setJenisKelompokMataPelajaranOpt] = useState([]);
  const [selectedJenisKelompokMataPelajaran, setSelectedJenisKelompokMataPelajaran] = useState(null);

  const loadJenisKelompokMataPelajaran = async (kelompok) => {
    console.log("loadJenisKelompokMataPelajaran : {}", kelompok);
    var response = await axiosInstance.get("select/jenis-kelompok-mata-pelajaran?kelompok_mata_pelajaran_id="+ kelompok.value + "&kurikulum=" + selectedKurikulum.value);
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      console.log("loadJenisKelompokMataPelajaran data:", data);
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].id, label: data[i].label });
      }
      setJenisKelompokMataPelajaranOpt(tmp);
    }
  }

  const handleSelectJenisKelompokMataPelajaran = (e) => {
    if (e) {
      setSelectedJenisKelompokMataPelajaran(e);
      loadMataPelajaran(e);
    }
  };

  const [jurusan, setJurusan] = useState([]);
  const [semester, setSemester] = useState([
    { value: 'ganjil', label: 'Ganjil'},
    { value: 'genap', label: 'Genap'},
  ]);
  const [mapel, setMapel] = useState([]);

  const loadMataPelajaran = async (jenisKelompok) => {
    var response = await axiosInstance.get("select/mata-pelajaran?jenis_kelompok_mata_pelajaran_id="+ jenisKelompok.value + "&kurikulum=" + selectedKurikulum.value);
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].id, label: data[i].label });
      }
      setMapel(tmp);
    }
  };

  const handleSelectMataPelajaran = (e) => {
    if (e) {
      setSelectedMataPelajaran(e);
    }
  };
  const [tingkatKesulitan, setTingkatKesulitan] = useState([]);

  const [kelas, setKelas] = useState([]);

  const handleSelectKurikulum = (e) => {
    if (e) {
      setSelectedKurikulum(e);
    }
  };

  const handleSelectJenjang = (e) => {
    if (e) {
      setSelectedKelas(null);
      setselectedJenjang(e);
      getJurusan(e.label);
      loadKelompokMataPelajaran(e);
      loadKelas(e.label);
    }
  };

  const handleSelectKelas = (e) => {
    if (e) {
      setSelectedKelas(e);
    }
  };

  const handleSelectJurusan = (e) => {
    if (e) {
      setSelectedJurusan(e);
    }
  };

  const handleSelectSemester = (e) => {
    if (e) {
      setSelectedSemester(e);
    }
  };

  const getJurusan = async (jenjang) => {
    var response = await axiosInstance.get("/select/jurusan?jenjang=" + jenjang);
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].id, label: data[i].text });
      }
      setJurusan(tmp);
    }
  };

  const checkForm = () => {
    if (!title) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Judul harus diisi',
      })
      return false;
    }

    if (!kurikulum){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Kurikulum harus dipilih',
      })
      return false;
    }
    if (!selectedJenjang) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Jenjang harus dipilih',
      })
      return false;
    }

    if (!selectedKelas) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Kelas harus dipilih',
      })
      return false;
    }

    if (!selectedJurusan) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Jurusan harus dipilih',
      })
      return false;
    }

    if (!selectedSemester) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Semester harus dipilih',
      })
      return false;
    }

    if (!selectedMataPelajaran) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Mata Pelajaran harus dipilih',
      })
      return false;
    }

    if (!indicator) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Indikator harus diisi',
        })
        return false;
    }

    if (!description) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Deskripsi harus diisi',
      })
      return false;
    }
    return true;
  };

  const handleSaveButton = async (data) => {
    if(!checkForm()){
      return;
    }

    var params = {
        "topic": title,
        "level": selectedLevel.value,
        "media": selectedMediaType.value,
        "media_url": "https://google.com",
        "price": 0,
        "indicator": indicator,
        "description": description,
        "detail": {
            "id_jenjang": selectedJenjang.value,
            "jenjang": selectedJenjang.label.split("/")[0],
            "id_kelas": selectedKelas.value,
            "kelas": selectedKelas.label,
            "id_jurusan": selectedJurusan.value,
            "jurusan": selectedJurusan.label,
            "id_kurikulum": selectedKurikulum.value,
            "kurikulum": selectedKurikulum.label,
            "id_kelompok_mata_pelajaran": selectedKelompokMataPelajaran.value,
            "kelompok_mata_pelajaran": selectedKelompokMataPelajaran.label,
            "id_jenis_kelompok_mata_pelajaran": selectedJenisKelompokMataPelajaran.value,
            "jenis_kelompok_mata_pelajaran": selectedJenisKelompokMataPelajaran.label,
            "id_mata_pelajaran": selectedMataPelajaran.value,
            "mata_pelajaran": selectedMataPelajaran.label,
        }
    
    }
    console.log("handle save button", params);
    return;
    if (action == "update") {
      let search = window.location.search;
      let urlparam = new URLSearchParams(search);
      let id = urlparam.get('id');
      var res = await axiosInstance.put(`/soal/${id}`, params);
      if (res.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Bahan Ajar berhasil diupdate',
        })
        navigate("/bahan-ajar");
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: res.data.message,
        })
      }
    }else if (action == "add") {
      var res = await axiosBahanAjar.post("bahan-ajar", params);
      if (res.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Bahan Ajar berhasil ditambahkan',
        })
        navigate("/bahan-ajar");
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: res.data.message,
        })
      }
    }
  };

  const prevKurikulumValue = useRef();
  const prevJenjangValue = useRef();
  const prevKelasValue = useRef();
  const prevJurusanValue = useRef();
  const prevKelompokMapel = useRef();
  const prevJenisKelompokMapel = useRef();
  const prevMapel = useRef();
  const wasShowKurikulumAlert = useRef();
  wasShowKurikulumAlert.current = false;

  useEffect(() => {
    if(dataDetail != null){
      setTitle(dataDetail.detail.materi)
    //   handleSelectTingkatKesulitan(tingkatKesulitan.find(e => e.value.toLowerCase() == dataDetail.detail.tingkat_sulit.toLowerCase()))

      setDescription(dataDetail.detail.indikator)

      setDefaultValue(dataDetail.value)

      if(!isEqual(prevKurikulumValue.current, kurikulum)){
        prevKurikulumValue.current = kurikulum;
        if(dataDetail.detail.kurikulum != null && dataDetail.detail.kurikulum != undefined){
          handleSelectKurikulum(kurikulum.find(e => e.value.includes(dataDetail.detail.kurikulum)))
        }
      }

      if((dataDetail.detail.kurikulum == null || dataDetail.detail.kurikulum == undefined) && !wasShowKurikulumAlert.current && selectedKurikulum == null){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Kurikulum tidak ditemukan',
        });
        wasShowKurikulumAlert.current = true;
        return;
      }
      console.log("prev jenjang value : ", prevJenjangValue.current);
      console.log("jenjang : ", jenjang);
      console.log("prevJenjangValue.current != jenjang : ", isEqual(prevJenjangValue.current, jenjang));
      if(!isEqual(prevJenjangValue.current, jenjang)){
        prevJenjangValue.current = jenjang;
        handleSelectJenjang(jenjang.find(e => e.value.includes(dataDetail.detail.jenjang)))
      }
      console.log("prev kelas value : ", prevKelasValue.current);
      console.log("kelas : ", kelas);
      console.log("prevKelasValue.current != kelas : ", isEqual(prevKelasValue.current, kelas));
      if(!isEqual(prevKelasValue.current, kelas)){
        // handleSelectJenjang(jenjang.find(e => e.value.includes(dataDetail.detail.jenjang)))

        prevKelasValue.current = kelas;
        handleSelectKelas(kelas.find(e => e.value.includes(dataDetail.detail.kelas)))
      }

      console.log("prev kelompokMataPelajaranOpt value : ", prevKelompokMapel.current);
      console.log("kelompokMataPelajaranOpt : ", kelompokMataPelajaranOpt);
      console.log("prevKelompokMapel.current != kelompokMataPelajaranOpt : ", isEqual(prevKelompokMapel.current, kelompokMataPelajaranOpt));

      if(!isEqual(prevKelompokMapel.current, kelompokMataPelajaranOpt)){
        prevKelompokMapel.current = kelompokMataPelajaranOpt;

        handleSelectKelompokMataPelajaran(kelompokMataPelajaranOpt.find(e => e.label.includes(dataDetail.detail.kelompok_mata_pelajaran)))
      }

      if(!isEqual(prevJenisKelompokMapel.current, jenisKelompokMataPelajaranOpt)){
        prevJenisKelompokMapel.current = jenisKelompokMataPelajaranOpt;

        handleSelectJenisKelompokMataPelajaran(jenisKelompokMataPelajaranOpt.find(e => e.label.includes(dataDetail.detail.jenis_kelompok_mata_pelajaran)))
      }

      if(!isEqual(prevMapel.current, mapel)){
        prevMapel.current = mapel;
        handleSelectMataPelajaran(mapel.find(e => e.label.includes(dataDetail.detail.mata_pelajaran)))
      }



      if(!isEqual(prevJurusanValue.current, jurusan)){
        prevJurusanValue.current = jurusan;

        handleSelectJurusan(jurusan.find(e => e.value.includes(dataDetail.detail.jurusan)))
      }

      handleSelectSemester(semester.find(e => e.value == dataDetail.detail.semester))

      // handleSelectMataPelajaran(mapel.find(e => e.value == dataDetail.detail.mata_pelajaran))
      // setSelectedMataPelajaran(dataDetail.detail.mata_pelajaran)

      
    }
  }, [dataDetail,kurikulum, jenjang, kelas, kelompokMataPelajaranOpt, jenisKelompokMataPelajaranOpt, mapel, jurusan])
  
  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files, allFiles) => {
    console.log('files : ', files);
    console.log('allFiles : ', allFiles)
    files.forEach(async (file) => {
      const formData = new FormData();
      formData.append("file", file.file); // Tambahkan file ke formData
        var res = await axiosInstance.post('/media/upload', formData);
        console.log(res);
        setMediaUrl(res.data.url);
    });

    // Hapus file dari tampilan setelah upload selesai
    allFiles.forEach((f) => f.remove());
  };

  const handleChangeStatus = ({ meta, file }, status) => {
    console.log(`Status file ${meta.name}: ${status}`);
    // setSelectedFiles((prevFiles) => prevFiles.filter((f) => f.meta.name !== file.meta.name));
  };

  const [selectedFiles, setSelectedFiles] = useState([]);
  const handleChange = (files) => {
    console.log('files : ', files);
    setSelectedFiles(files); // Menyimpan file yang dipilih ke dalam state
  };

  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="row">
          <div className="card">
            <div className="card-header">
              <div className="col-sm-8">
                <h4>{action == "add" ? "Tambah" : action == "update" ? "Ubah" : "Rincian"} Butir Soal</h4>
              </div>
            </div>
            <div className="card-body nm">
              <div className="row">
                <div className="col-sm-4 border-right">
                  <div className="m-1 mt-4 mb-5">
                    <h5 className="text text-primary">Pengaturan</h5>
                    <div className="row mt-2">
                      <div className="col-4">
                        <span className="text-primary mt-3">Topik</span>
                      </div>
                      <div className="col-8">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Masukkann Topik"
                          onChange={(e) => setTitle(e.target.value)}
                          value={title}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-4">
                        <span className="text-primary mt-3">Media</span>
                      </div>
                      <div className="col-8">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={jenjang[0]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={mediaType}
                          onChange={setSelectedMediaType}
                          value={selectedMediaType}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-4">
                        <span className="text-primary mt-3">Level</span>
                      </div>
                      <div className="col-8">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={levelOpt}
                          onChange={setSelectedLevel}
                          value={selectedLevel}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-4">
                        <span className="text-primary mt-3">Kurikulum</span>
                      </div>
                      <div className="col-8">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={jenjang[0]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={kurikulum}
                          onChange={handleSelectKurikulum}
                          value={selectedKurikulum}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-4">
                        <span className="text-primary mt-3">Jenjang</span>
                      </div>
                      <div className="col-8">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={jenjang[0]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={jenjang}
                          onChange={handleSelectJenjang}
                          value={selectedJenjang}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-4">
                        <span className="text-primary mt-3">Kelompok Mata Pelajaran</span>
                      </div>
                      <div className="col-8">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={jenjang[0]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={kelompokMataPelajaranOpt}
                          onChange={handleSelectKelompokMataPelajaran}
                          value={selectedKelompokMataPelajaran}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-4">
                        <span className="text-primary mt-3">Jenis Kelompok Mata Pelajaran</span>
                      </div>
                      <div className="col-8">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={jenjang[0]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={jenisKelompokMataPelajaranOpt}
                          onChange={handleSelectJenisKelompokMataPelajaran}
                          value={selectedJenisKelompokMataPelajaran}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-4">
                        <span className="text-primary mt-3">
                          Mata Pelajaran
                        </span>
                      </div>
                      <div className="col-8">
                      {/* <input
                          type="text"
                          className="form-control"
                          placeholder="Masukkann Mata Pelajaran"
                          value={selectedMataPelajaran}
                          onChange={(e) => setSelectedMataPelajaran(e.target.value)}
                          disabled={isDisabled}
                        /> */}
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={mapel[0]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={mapel}
                          value={selectedMataPelajaran}
                          onChange={handleSelectMataPelajaran}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-4">
                        <span className="text-primary mt-3">Kelas</span>
                      </div>
                      <div className="col-8">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={kelas[0]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={kelas}
                          onChange={handleSelectKelas}
                          value={selectedKelas}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-4">
                        <span className="text-primary mt-3">Jurusan</span>
                      </div>
                      <div className="col-8">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={jurusan[0]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={jurusan}
                          onChange={handleSelectJurusan}
                          value={selectedJurusan}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-4">
                        <span className="text-primary mt-3">Semester</span>
                      </div>
                      <div className="col-8">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={semester[0]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={semester}
                          onChange={handleSelectSemester}
                          value={selectedSemester}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-4">
                        <span className="text-primary mt-3">Indikator</span>
                      </div>
                      <div className="col-8">
                        <textarea
                          name=""
                          className="form-control"
                          id=""
                          value={indicator}
                          onChange={(e) => setIndicator(e.target.value)}
                          disabled={isDisabled}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-8 custom-ckeditor">
                  <div className="m-1 mt-4">
                    {mediaUrl == null && <Dropzone
                        onChangeStatus={handleChangeStatus}
                        onSubmit={handleSubmit}
                        onChange={handleChange}
                        autoUpload={false}
                        maxFiles={1}
                        accept="image/*,audio/*,video/*"
                        inputContent="Pilih file atau Tarik file"
                        files={selectedFiles}
                    />}
                    {mediaUrl != null && (
                        <div>
                            <button className="btn btn-sm m-1 btn-primary" onClick={()=>window.open(mediaUrl, '_blank')}>Buka Media</button>
                            <button className="btn btn-sm m-1 btn-primary" onClick={setMediaUrl(null)}>Hapus Media</button>
                        </div>
                    )}
                    <CKEditor
                        className="mt-4"
                        config={{
                            placeholder: "Tuliskan Deskripsi di sini...",
                            extraPlugins: [uploadPlugin]
                        }}
                        editor={ClassicEditor}
                        onReady={(editor) => {}}
                        data={description}
                        // disabled={props.isDisabled}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setDescription(data);
                        }}
                        onBlur={(event, editor) => {}}
                        onFocus={(event, editor) => {}}
                    />
                    <div className="row mt-3 mb-4">
                        <div className="col-sm-12 text-end">
                        {/* <button className="btn btn-sm m-1 btn-outline-primary">
                            Simpan Draft
                        </button> */}
                        <button className="btn btn-sm m-1 btn-primary" onClick={handleSaveButton}>Simpan</button>
                        </div>
                    </div>
                    {/* {type == "pilihan-ganda" ? (
                      <PilihanGanda jumlahpg={selectedJumlahPg.value} groupsoal={selectedGroupSoal.value} onSaveButton={handleSaveButton} defaultValue={defaultValue} isDisabled={isDisabled} />
                    ) : type == "sebab-akibat" ? (
                      <SebabAkibat jumlahpg={selectedJumlahPg.value} onSaveButton={handleSaveButton} defaultValue={defaultValue} isDisabled={isDisabled} />
                    ) : type == "uraian" ? (
                      <Uraian onSaveButton={handleSaveButton} defaultValue={defaultValue} isDisabled={isDisabled}/>
                    ) : type == "penjodohan" ? (
                      <Penjodohan onSaveButton={handleSaveButton} defaultValue={defaultValue} isDisabled={isDisabled}/>
                    ) : (
                      <BenarSalah onSaveButton={handleSaveButton} defaultValue={defaultValue} isDisabled={isDisabled}/>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBahanAjar;
