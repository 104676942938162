import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Dropdown, Modal } from "react-bootstrap";
import swal from "sweetalert";
import { nanoid } from "nanoid";
import { IMAGES } from "../Dashboard/Content";
import CardKartuSoal from "./ShowPage/Card";
import TableKartuSoal from "./ShowPage/Table";
import axiosInstance from "../../../services/AxiosInstance";
import { connect } from "react-redux";
import { isAuthenticated } from "../../../store/selectors/AuthSelectors";
import { useLoading } from "../Global/LoadingProvider";
// const [cardListBlog, setCardListBlog] = useState([]);
// const CardListBlog = [
  // {
  //   kode_paket: "20-12345",
  //   paket_soal_name: "Bahasa Indonesia No",
  //   author: "Dona Wariska",
  //   is_draft: true,
  //   jenis_soal: [
  //     {
  //       name: "PG",
  //       color: "primary",
  //     },
  //   ],
  // },
  // {
  //   kode_paket: "20-12345",
  //   paket_soal_name: "Bahasa Indonesia No",
  //   author: "Dona Wariska",
  //   is_draft: true,
  //   jenis_soal: [
  //     {
  //       name: "PG",
  //       color: "primary",
  //     },
  //   ],
  // },
  // {
  //   kode_paket: "20-12345",
  //   paket_soal_name: "Bahasa Indonesia No",
  //   author: "Dona Wariska",
  //   is_draft: false,
  //   jenis_soal: [
  //     {
  //       name: "PG",
  //       color: "primary",
  //     },
  //   ],
  // },
  // {
  //   kode_paket: "20-12345",
  //   paket_soal_name: "Bahasa Indonesia No",
  //   author: "Dona Wariska",
  //   is_draft: false,
  //   jenis_soal: [
  //     {
  //       name: "PG",
  //       color: "primary",
  //     },
  //   ],
  // },
  // {
  //   kode_paket: "20-12345",
  //   paket_soal_name: "Bahasa Indonesia No",
  //   author: "Dona Wariska",
  //   is_draft: true,
  //   jenis_soal: [
  //     {
  //       name: "PG",
  //       color: "primary",
  //     },
  //   ],
  // },
  // {
  //   kode_paket: "20-12345",
  //   paket_soal_name: "Bahasa Indonesia No",
  //   author: "Dona Wariska",
  //   is_draft: true,
  //   jenis_soal: [
  //     {
  //       name: "PG",
  //       color: "primary",
  //     },
  //   ],
  // },
  // {
  //   kode_paket: "20-12345",
  //   paket_soal_name: "Bahasa Indonesia No",
  //   author: "Dona Wariska",
  //   is_draft: false,
  //   jenis_soal: [
  //     {
  //       name: "U",
  //       color: "danger",
  //     },
  //   ],
  // },
  // {
  //   kode_paket: "20-12345",
  //   paket_soal_name: "Bahasa Indonesia No",
  //   author: "Dona Wariska",
  //   is_draft: true,
  //   jenis_soal: [
  //     {
  //       name: "U",
  //       color: "danger",
  //     },
  //   ],
  // },
  // {
  //   kode_paket: "20-12345",
  //   paket_soal_name: "Bahasa Indonesia No",
  //   author: "Dona Wariska",
  //   is_draft: false,
  //   jenis_soal: [
  //     {
  //       name: "U",
  //       color: "danger",
  //     },
  //   ],
  // },
  // {
  //   kode_paket: "20-12345",
  //   paket_soal_name: "Bahasa Indonesia No",
  //   author: "Dona Wariska",
  //   is_draft: true,
  //   jenis_soal: [
  //     {
  //       name: "U",
  //       color: "danger",
  //     },
  //   ],
  // },
  // {
  //   kode_paket: "20-12345",
  //   paket_soal_name: "Bahasa Indonesia No",
  //   author: "Dona Wariska",
  //   is_draft: true,
  //   jenis_soal: [
  //     {
  //       name: "U",
  //       color: "danger",
  //     },
  //   ],
  // },
  // {
  //   kode_paket: "20-12345",
  //   paket_soal_name: "Bahasa Indonesia No",
  //   author: "Dona Wariska",
  //   is_draft: true,
  //   jenis_soal: [
  //     {
  //       name: "U",
  //       color: "danger",
  //     },
  //   ],
  // },
// ];

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const ButirSoal = (props) => {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [soal, setSoal] = useState([]);
  const [showStyle, setShowStyle] = useState('card');
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(15);

  const { setLoading } = useLoading();

  const getColorByTypeSoal = (type) => {
    switch (type) {
      case "PG":
        return "primary";
      case "SA":
        return "warning";
      case "BS":
        return "success";
      case "UR":
        return "danger";
      default:
        return "primary";
    }
  };

  const typeByJenisSoal = (type) => {
    switch (type) {
      case "PG":
        return "pilihan-ganda";
      case "SA":
        return "sebab-akibat";
      case "BS":
        return "benar-salah";
      case "UR":
        return "uraian";
      case "PJ":
        return "penjodohan";
      case "MPG":
        return "pilihan-ganda";
      default:
        return "PG";
    }
  };

  const loadButirSoal = async () => {
    
    try {
      setLoading(true);
      const response = await axiosInstance.get(`soal?per_page=${perPage}&page=${currentPage}&materi=${searchKeyword}`);
      var data = response.data.data;
      var tmp = [];
      for (var i = 0; i < data.length; i++) {
        tmp.push({
          id: data[i].id,
          kode_paket: data[i].code,
          paket_soal_name: data[i].detail.materi,
          author: props.user.name,
          is_draft: false,
          is_bursa: data[i].is_bursa,
          jenis_soal: [
            {
              name: data[i].type,
              color: getColorByTypeSoal(data[i].type),
            },
          ],
          type: data[i].type,
          type_style: typeByJenisSoal(data[i].type),
        });
      }

      setSoal(tmp);
      setTotal(response.data.total);
      setCurrentPage(response.data.current_page);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    loadButirSoal();
  }, [currentPage, searchKeyword]);

  const onDelete = async (id) => {
    try {
      const response = await axiosInstance.delete(`soal/${id}`);
      console.log(response);
      loadButirSoal();
    } catch (error) {
      console.log(error);
    }
  }

  const handlePaging = (page) => {
    console.log("page:", page);
    setCurrentPage(page);
  }
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="page-title flex-wrap">
          <div className="input-group search-area mb-md-0 mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Cari disini..."
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  console.log("Enter", e);
                  setSearchKeyword(e.target.value);
                }
              }}
            />
            <span className="input-group-text">
              <Link to={"#"}>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z"
                    fill="#01A3FF"
                  />
                </svg>
              </Link>
            </span>
          </div>
          <div className="d-flex">
            <ul
              as="ul"
              className="nav nav-tabs dzm-tabs me-2"
              id="myTab"
              role="tablist"
            >
              <li as="li" className="nav-item" role="presentation">
                <button className={`nav-link ${showStyle == 'card' ? 'active' : ''}`} type="button" onClick={()=>setShowStyle('card')}>
                  <i className="fas fa-address-card"></i>
                </button>
              </li>
              <li as="li" className="nav-item">
                <button className={`nav-link ${showStyle == 'list' ? 'active' : ''}`} type="button" onClick={()=>setShowStyle('list')}>
                  <i className="fas fa-list"></i>
                </button>
              </li>
            </ul>

            <Link
              type="button"
              className="btn btn-primary"
              to={"/kartu-soal/add/pilihan-ganda"}
            >
              + Create Butir Soal
            </Link>
          </div>
        </div>
      </div>
      {showStyle == 'card' ? 
        (<CardKartuSoal contacts={soal} onBursa={loadButirSoal} onDelete={onDelete} total={total} perPage={perPage} onPaging={handlePaging} />)
        :
        (<TableKartuSoal contacts={soal} onBursa={loadButirSoal} onDelete={onDelete} total={total} perPage={perPage} onPaging={handlePaging}/>)
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log('mapStateToProps butirsoal', state);
  return {
    isAuthenticated: isAuthenticated(state),
    user: state.auth.auth,
  };
};

export default withRouter(connect(mapStateToProps)(ButirSoal));
