import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../../../services/AxiosInstance";
import Swal from "sweetalert2";
import Pagination from "../../Pagination";

const CardBahanAjar = (props) => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const [selectedSoal, setSelectedSoal] = useState(null);

  const handleShow = (soal) => {
    setShow(true);
    setSelectedSoal(soal);
  };
  const handleClose = () => {
    setShow(false)
    setSelectedSoal(null)
  };

  const [price, setPrice] = useState(0);

  const handleSubmitBursa = async () => {
    if (!price) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Harga harus di isi'
      })
      return
    }
    console.log("price:", price);
    console.log("selectedSoal:", selectedSoal);
    var params = {
      price: price
    }
    var resPrice = await axiosInstance.put(`soal/price/${selectedSoal.id}`, params);
    var resBursa = await axiosInstance.put(`soal/activate-bursa/${selectedSoal.id}`, {});
    if (resPrice.data && resBursa.data) {
      props.onBursa(true)
      handleClose();
    }
  };

  const goTo = (action, contact) => {
    console.log("goTo", contact);
    navigate(`/kartu-soal/${action}/${contact.type_style}?id=${contact.id}`);
  }

  const onPaging = (page) => {
    props.onPaging(page);
  }

  return (
    <div className="col-xl-12">
      <div className="row">
        {props.contacts.map((contact, index) => (
          <div className="col-xl-3 col-lg-4 col-sm-6" key={index}>
            <div className="card p-0 contact_list">
              <div className="card-body p-3">
                <div className="user-content">
                  <div className="user-info" style={{ cursor: "pointer" }} onClick={() => goTo("detail", contact)}>
                    <div className="div">
                      <h6 className="text-muted">{contact.id_bahan_ajar}</h6>
                    </div>
                    <div className="user-details">
                      <h4 className="user-name mb-0">
                        {contact.topic}
                      </h4>
                      <p>by {contact.creator}</p>
                    </div>
                  </div>
                </div>
                <div className="contact-icon">

                  {contact.status ? (
                    <div className="badge badge-dark">{contact.status}</div>
                  ) : (
                    ""
                  )}

                  {/* {contact.is_bursa ? (
                    <div className="badge badge-dark">Bursa</div>
                  ) : (
                    ""
                  )} */}
                </div>
                <hr />

                <div className=" d-flex justify-content-between flex-wrap">
                  <div
                    className="btn-group btn-group-sm"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm text-danger"
                      onClick={() => props.onDelete(contact.id)}
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm text-warning"
                      onClick={() => goTo('update', contact)}
                    >
                      <i className="fas fa-pencil-alt"></i>
                    </button>
                    {!contact.is_draft && !contact.is_bursa ? (
                      <button
                        type="button"
                        onClick={() => handleShow(contact)}
                        className="btn btn-outline-primary btn-sm"
                      >
                        <i className="fas fa-plus"></i> Bursa
                      </button>
                    ) : (
                      ""
                    )}
                  </div>

                  <Link
                    to={`/kartu-soal/statistik/${contact.kode_paket}`}
                    className="btn btn-sm btn-outline-primary"
                  >
                    <i className="fas fa-chart-bar"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Modal dialogClassName="modal-lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Bursa Kartu Soal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="form-group">
            <b className="mb-3">Sampul</b>
            <Dropzone
              inputContent="Drop files here to upload"
              className="mt-3"
              accept="image/*,audio/*,video/*"
              styles={{
                dropzone: {
                  minHeight: 200,
                  maxHeight: 250,
                  width: "100%",
                  backgroundColor: "#f2f4fa",
                  border: "1px dashed #DDDFE1",
                  overflow: "hidden",
                },
                inputLabel: {
                  color: "#7e7e7e",
                  fontSize: "16px",
                  fontWeight: "normal",
                  backgroundColor: "#f2f4fa",
                },
              }}
            />
          </div> */}
          <div className="form-group mt-4">
            <b className="mb-3">Harga</b>
            <input type="number" className="form-control mt-2" placeholder="Masukkan Harga Scoin" value={price} onChange={(e) => setPrice(e.target.value)}/>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-secondary" onClick={handleClose}>
            Close
          </button>
          <button className="btn btn-outline-primary" onClick={handleSubmitBursa}>
            Masukkan Bursa
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CardBahanAjar;
