import React, { useEffect, useRef, useState } from "react";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Dropdown } from "react-bootstrap";
import PilihanGanda from "./JenisSoal/PilihanGanda";
import Uraian from "./JenisSoal/Uraian";
import Penjodohan from "./JenisSoal/Penjodohan";
import Select from "react-select";
import BenarSalah from "./JenisSoal/BenarSalah";
import { useNavigate, useParams } from "react-router-dom";
import SebabAkibat from "./JenisSoal/SebabAkibat";
import axiosInstance from "../../../services/AxiosInstance";
import Swal from "sweetalert2";
import { isEqual } from "../../../utils";
// import axios from "axios";

const AddButirSoal = () => {
  const { action, type } = useParams();
  const navigate = useNavigate();
  const [dataDetail, setDataDetail] = useState(null);
  const [typeSoal, setTypeSoal] = useState([
    { value: "pilihan-ganda", label: "Pilihan Ganda" },
    { value: "sebab-akibat", label: "Sebab Akibat" },
    { value: "uraian", label: "Uraian" },
    { value: "penjodohan", label: "Penjodohan" },
    { value: "benar-salah", label: "Benar Salah" },
  ]);


  useEffect(() => {
    console.log("typesoal:", typeSoal);
    console.log("type:", type);
    setSelectedTypeSoal(typeSoal.find((item) => item.value == type));
    getTingkatKesulitan();
    loadJenjang();
    loadKurikulum();
    // loadMataPelajaran();
    if (action == "detail") {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let id = params.get('id');
      loadDetailButirSoal(id);
      setIsDisabled(true);
    }
    if (action == "update") {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let id = params.get('id');
      loadDetailButirSoal(id);
    }
  }, [action]);
  
  const loadDetailButirSoal = async (id) => {
    try {
      const response = await axiosInstance.get(`soal/${id}?with_jawaban=true`);
      var data = response.data.data;
      console.log("data:", data);
      setDataDetail(data);
      // setSelectedKelas(data.kelas);
      // setSelectedJurusan(data.jurusan);
      // setSelectedTingkatKesulitan(data.tingkat_kesulitan);
      // setSelectedSemester(data.semester);
      // setSelectedMataPelajaran(data.mata_pelajaran);
      // setTitle(data.title);
      // setDescription(data.description);
    } catch (error) {
      console.log(error);
    }
  };

  const typeByJenisSoal = (type) => {
    switch (type) {
      case "PG":
        return "pilihan-ganda";
      case "SA":
        return "sebab-akibat";
      case "BS":
        return "benar-salah";
      case "UR":
        return "urian";
      case "PJ":
        return "penjodohan";
      default:
        return "PG";
    }
  };

  const [selectedTypeSoal, setSelectedTypeSoal] = useState();
  const [selectedJumlahPg, setSelectedJumlahPg] = useState({ value: 3, label: "3" });
  const [selectedGroupSoal, setSelectedGroupSoal] = useState({ value: 1, label: "No" });
  const [selectedJenjang, setselectedJenjang] = useState(null);
  const [selectedKelas, setSelectedKelas] = useState(null);
  const [selectedJurusan, setSelectedJurusan] = useState(null);
  const [selectedTingkatKesulitan, setSelectedTingkatKesulitan] = useState(null);
  const [selectedSemester, setSelectedSemester] = useState(null);
  const [selectedMataPelajaran, setSelectedMataPelajaran] = useState(null);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [defaultValue, setDefaultValue] = useState(null);

  const [isClearable, setIsClearable] = useState(false);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);

  const [colourOptions, setColourOptions] = useState([
    { value: "red", label: "Red" },
    { value: "blue", label: "Blue" },
    { value: "green", label: "Green" },
  ]);

  // let initialTypeSoal;

  // if (type === "pilihan-ganda") {
  // let initialTypeSoal = [
  //     { value: "pilihan-ganda", label: "Pilihan Ganda" },
  //     { value: "sebab-akibat", label: "Sebab Akibat" },
  //     { value: "uraian", label: "Uraian" },
  //     { value: "penjodohan", label: "Penjodohan" },
  //     { value: "benar-salah", label: "Benar Salah" },
  //   ];
  // } else if (type === "sebab-akibat") {
  //   initialTypeSoal = [
  //     { value: "sebab-akibat", label: "Sebab Akibat" },
  //     { value: "uraian", label: "Uraian" },
  //     { value: "penjodohan", label: "Penjodohan" },
  //     { value: "benar-salah", label: "Benar Salah" },
  //     { value: "pilihan-ganda", label: "Pilihan Ganda" },
  //   ];
  // } else if (type === "uraian") {
  //   initialTypeSoal = [
  //     { value: "uraian", label: "Uraian" },
  //     { value: "penjodohan", label: "Penjodohan" },
  //     { value: "benar-salah", label: "Benar Salah" },
  //     { value: "pilihan-ganda", label: "Pilihan Ganda" },
  //     { value: "sebab-akibat", label: "Sebab Akibat" },
  //   ];
  // } else if (type === "penjodohan") {
  //   initialTypeSoal = [
  //     { value: "penjodohan", label: "Penjodohan" },
  //     { value: "pilihan-ganda", label: "Pilihan Ganda" },
  //     { value: "sebab-akibat", label: "Sebab Akibat" },
  //     { value: "benar-salah", label: "Benar Salah" },
  //     { value: "uraian", label: "Uraian" },
  //   ];
  // } else if (type === "benar-salah") {
  //   initialTypeSoal = [
  //     { value: "benar-salah", label: "Benar Salah" },
  //     { value: "pilihan-ganda", label: "Pilihan Ganda" },
  //     { value: "sebab-akibat", label: "Sebab Akibat" },
  //     { value: "uraian", label: "Uraian" },
  //     { value: "penjodohan", label: "Penjodohan" },
  //   ];
  // }


  const [jumlahPg, setJumlahPg] = useState([
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
  ]);

  const [groupSoal, setGroupSoal] = useState([
    { value: 1, label: "No" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
  ]);

  const [jenjang, setJenjang] = useState([
    // { value: "PAUD", label: "PAUD" },
    // { value: "SD/MI", label: "SD/MI" },
    // { value: "SMP/MTs", label: "SMP/MTs" },
    // { value: "SMA/MA", label: "SMA/MA" },
    // { value: "SMK/MAK", label: "SMK/MAK" },
  ]);

  const loadJenjang = async () => {
    var response = await axiosInstance.get("select/jenjang");
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      console.log("loadJenjang data:", data);
      for (var i = 0; i < data.length; i++) {
        tmp.push({id: data[i].id, value: data[i].jenjang, label: data[i].jenjang });
      }
      setJenjang(tmp);
    }
  }

  const [kurikulum, setKurikulum] = useState([]);
  const [selectedKurikulum, setSelectedKurikulum] = useState(null);

  const loadKurikulum = async () => {
    var response = await axiosInstance.get("/select/kurikulum");
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].value, label: data[i].label });
      }
      setKurikulum(tmp);
    }
  };

  const loadKelas = async (jenjang) => {
    var response = await axiosInstance.get("/select/kelas?jenjang=" + jenjang);
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].value, label: data[i].text });
      }
      setKelas(tmp);
    }
  };

  const [kelompokMataPelajaranOpt, setKelompokMataPelajaranOpt] = useState([]);
  const [selectedKelompokMataPelajaran, setSelectedKelompokMataPelajaran] = useState(null);

  const loadKelompokMataPelajaran = async (jenjang) => {
    console.log("loadKelompokMataPelajaran : {}", jenjang, " kurikulum : {}", selectedKurikulum);

    var response = await axiosInstance.get("select/kelompok-mata-pelajaran?jenjang_id="+ jenjang.id + "&kurikulum=" + selectedKurikulum.value);
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      console.log("loadKelompokMataPelajaran data:", data);
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].id, label: data[i].label });
      }
      setKelompokMataPelajaranOpt(tmp);
    }
  };

  const handleSelectKelompokMataPelajaran = (e) => {
    if (e) {
      setSelectedKelompokMataPelajaran(e);
      loadJenisKelompokMataPelajaran(e);
    }
  };

  const [jenisKelompokMataPelajaranOpt, setJenisKelompokMataPelajaranOpt] = useState([]);
  const [selectedJenisKelompokMataPelajaran, setSelectedJenisKelompokMataPelajaran] = useState(null);

  const loadJenisKelompokMataPelajaran = async (kelompok) => {
    console.log("loadJenisKelompokMataPelajaran : {}", kelompok);
    var response = await axiosInstance.get("select/jenis-kelompok-mata-pelajaran?kelompok_mata_pelajaran_id="+ kelompok.value + "&kurikulum=" + selectedKurikulum.value);
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      console.log("loadJenisKelompokMataPelajaran data:", data);
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].id, label: data[i].label });
      }
      setJenisKelompokMataPelajaranOpt(tmp);
    }
  }

  const handleSelectJenisKelompokMataPelajaran = (e) => {
    if (e) {
      setSelectedJenisKelompokMataPelajaran(e);
      loadMataPelajaran(e);
    }
  };

  const [jurusan, setJurusan] = useState([]);
  const [semester, setSemester] = useState([
    { value: 'ganjil', label: 'Ganjil'},
    { value: 'genap', label: 'Genap'},
  ]);
  const [mapel, setMapel] = useState([]);

  const loadMataPelajaran = async (jenisKelompok) => {
    var response = await axiosInstance.get("select/mata-pelajaran?jenis_kelompok_mata_pelajaran_id="+ jenisKelompok.value + "&kurikulum=" + selectedKurikulum.value);
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].id, label: data[i].label });
      }
      setMapel(tmp);
    }
  };

  const handleSelectMataPelajaran = (e) => {
    if (e) {
      setSelectedMataPelajaran(e);
    }
  };
  const [tingkatKesulitan, setTingkatKesulitan] = useState([]);

  const [kelas, setKelas] = useState([]);

  const handleSelectTypeSoal = (e) => {
    if (e) {
      setSelectedTypeSoal(e);
      const selectedPage = e.value;
      navigate("/kartu-soal/"+ action +"/" + selectedPage);
    }
  };

  const handleSelectJumlahPg = (e) => {
    if (e) {
      setSelectedJumlahPg(e);
    }
  };

  const handleGroupSoal = (e) => {
    console.log("handleGroupSoal", e);
    // if (e) {
      setSelectedGroupSoal(e);
    // }
  };

  const handleSelectKurikulum = (e) => {
    if (e) {
      console.log("setSelectedKurikulum handleSelectKurikulum", e);
      setSelectedKurikulum(e);
    }
  };

  const handleSelectJenjang = (e) => {
    if (e) {
      setSelectedKelas(null);
      setselectedJenjang(e);
      getJurusan(e.value);
      loadKelompokMataPelajaran(e);
      loadKelas(e.value);
      // if (e.value == "PAUD") {
      //   setKelas([{ value: "0", label: "0" }]);
      // } else if (e.value == "SD/MI") {
      //   setKelas([
      //     { value: "I", label: "I" },
      //     { value: "II", label: "II" },
      //     { value: "III", label: "III" },
      //     { value: "IV", label: "IV" },
      //     { value: "V", label: "V" },
      //     { value: "VI", label: "VI" },
      //   ]);
      // } else if (e.value == "SMP/MTs") {
      //   setKelas([
      //     { value: "VII", label: "VII" },
      //     { value: "VIII", label: "VIII" },
      //     { value: "IX", label: "IX" },
      //   ]);
      // } else if (e.value == "SMA/MA") {
      //   setKelas([
      //     { value: "X", label: "X" },
      //     { value: "XI", label: "XI" },
      //     { value: "XII", label: "XII" },
      //   ]);
      // } else if (e.value == "SMK/MAK") {
      //   setKelas([
      //     { value: "X", label: "X" },
      //     { value: "XI", label: "XI" },
      //     { value: "XII", label: "XII" },
      //   ]);
      // }
    }
  };

  const handleSelectKelas = (e) => {
    if (e) {
      setSelectedKelas(e);
    }
  };

  const handleSelectJurusan = (e) => {
    if (e) {
      setSelectedJurusan(e);
    }
  };

  const handleSelectSemester = (e) => {
    if (e) {
      setSelectedSemester(e);
    }
  };

  const handleSelectTingkatKesulitan = (e) => {
    if (e) {
      setSelectedTingkatKesulitan(e);
    }
  };

  const getJurusan = async (jenjang) => {
    var response = await axiosInstance.get("/select/jurusan?jenjang=" + jenjang);
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].value, label: data[i].text });
      }
      setJurusan(tmp);
    }
  };

  const getTingkatKesulitan = async () => {
    var response = await axiosInstance.get("/select/tingkat-sulit");
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].value, label: data[i].text });
      }
      setTingkatKesulitan(tmp);
    }
  }

  const getTypeCode = (type) => {
    if (type == "pilihan-ganda") {
      return "PG";
    } else if (type == "benar-salah") {
      return "BS";
    } else if (type == "uraian") {
      return "UR";
    } else if (type == "penjodohan") {
      return "PJ";
    } else if (type == "sebab-akibat") {
      return "SA";
    }
  };

  const checkForm = () => {
    if (!title) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Judul harus diisi',
      })
      return false;
    }

    if (!kurikulum){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Kurikulum harus dipilih',
      })
      return false;
    }
    if (!selectedJenjang) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Jenjang harus dipilih',
      })
      return false;
    }

    if (!selectedKelas) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Kelas harus dipilih',
      })
      return false;
    }

    if (!selectedJurusan) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Jurusan harus dipilih',
      })
      return false;
    }

    if (!selectedSemester) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Semester harus dipilih',
      })
      return false;
    }

    if (!selectedMataPelajaran) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Mata Pelajaran harus dipilih',
      })
      return false;
    }

    if (!selectedTingkatKesulitan) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Tingkat Kesulitan harus dipilih',
      })
      return false;
    }
    if (!description) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Deskripsi harus diisi',
      })
      return false;
    }
    return true;
  };

  const handleSaveButton = async (data, redirect) => {
    console.log("handleSaveButton", data);
    // navigate(`/kartu-soal/${action}/${selectedTypeSoal.value}`);
    // window.location.reload();
    // return;
    if(!checkForm()){
      return;
    }
    var params = {
      "type": selectedGroupSoal.value > 1 ? 'MPG' : getTypeCode(selectedTypeSoal.value),
      "detail": {
          "materi": title,
          "kurikulum": selectedKurikulum.value,
          "jenjang": selectedJenjang.value.split("/")[0],
          "kelas": selectedKelas.value,
          "jurusan": selectedJurusan.value,
          "semester": selectedSemester.value,
          "kelompok_mata_pelajaran": selectedKelompokMataPelajaran.label,
          "jenis_kelompok_mata_pelajaran": selectedJenisKelompokMataPelajaran.label,
          "mata_pelajaran": selectedMataPelajaran.label,
          "tingkat_sulit": selectedTingkatKesulitan.value.toLowerCase(),
          "indikator": description,
          "image": null
      },
      "value": data
    }
    console.log("handle save button", params);
    // return;
    if (action == "update") {
      let search = window.location.search;
      let urlparam = new URLSearchParams(search);
      let id = urlparam.get('id');
      var res = await axiosInstance.put(`/soal/${id}`, params);
      if (res.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Soal berhasil diupdate',
        })
        if (!redirect) {
          navigate("/kartu-soal");
        }
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: res.data.message,
        })
      }
    }else if (action == "add") {
      var res = await axiosInstance.post("/soal", params);
      if (res.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Soal berhasil ditambahkan',
        })
        // navigate("/kartu-soal");
        if (!redirect) {
          navigate("/kartu-soal");
        }
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: res.data.message,
        })
      }
    }
  };

  const prevKurikulumValue = useRef();
  const prevJenjangValue = useRef();
  const prevKelasValue = useRef();
  const prevJurusanValue = useRef();
  const prevKelompokMapel = useRef();
  const prevJenisKelompokMapel = useRef();
  const prevMapel = useRef();
  const wasShowKurikulumAlert = useRef();
  wasShowKurikulumAlert.current = false;

  useEffect(() => {
    if(dataDetail != null){
      setTitle(dataDetail.detail.materi)
      handleSelectTingkatKesulitan(tingkatKesulitan.find(e => e.value.toLowerCase() == dataDetail.detail.tingkat_sulit.toLowerCase()))

      setDescription(dataDetail.detail.indikator)

      setDefaultValue(dataDetail.value)

      if(dataDetail.type == "MPG"){
        handleGroupSoal(groupSoal.find(e => e.value == dataDetail.value.soal.length))
        handleSelectJumlahPg(jumlahPg.find(e => e.value == Object.keys(dataDetail.value.soal[0].pilihan).length))
      }
      if(dataDetail.type == "PG"){
        handleGroupSoal(groupSoal.find(e => e.value == 1))
        handleSelectJumlahPg(jumlahPg.find(e => e.value == Object.keys(dataDetail.value.pilihan).length))
      }

      if(dataDetail.type == "SA"){
        handleSelectJumlahPg(jumlahPg.find(e => e.value == Object.keys(dataDetail.value.pilihan).length))
      }

      if(!isEqual(prevKurikulumValue.current, kurikulum)){
        prevKurikulumValue.current = kurikulum;
        if(dataDetail.detail.kurikulum != null && dataDetail.detail.kurikulum != undefined){
          console.log("use effect handleSelectKurikulum : ", kurikulum);
          handleSelectKurikulum(kurikulum.find(e => e.value.includes(dataDetail.detail.kurikulum)))
        }
      }

      if((dataDetail.detail.kurikulum == null || dataDetail.detail.kurikulum == undefined) && !wasShowKurikulumAlert.current && selectedKurikulum == null){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Kurikulum tidak ditemukan',
        });
        wasShowKurikulumAlert.current = true;
        return;
      }
      console.log("prev jenjang value : ", prevJenjangValue.current);
      console.log("jenjang : ", jenjang);
      console.log("prevJenjangValue.current != jenjang : ", isEqual(prevJenjangValue.current, jenjang));
      if(!isEqual(prevJenjangValue.current, jenjang) && selectedKurikulum != null){
        prevJenjangValue.current = jenjang;
        handleSelectJenjang(jenjang.find(e => e.value.includes(dataDetail.detail.jenjang)))
      }
      console.log("prev kelas value : ", prevKelasValue.current);
      console.log("kelas : ", kelas);
      console.log("prevKelasValue.current != kelas : ", isEqual(prevKelasValue.current, kelas));
      if(!isEqual(prevKelasValue.current, kelas)){
        // handleSelectJenjang(jenjang.find(e => e.value.includes(dataDetail.detail.jenjang)))

        prevKelasValue.current = kelas;
        handleSelectKelas(kelas.find(e => e.value.includes(dataDetail.detail.kelas)))
      }

      console.log("prev kelompokMataPelajaranOpt value : ", prevKelompokMapel.current);
      console.log("kelompokMataPelajaranOpt : ", kelompokMataPelajaranOpt);
      console.log("prevKelompokMapel.current != kelompokMataPelajaranOpt : ", isEqual(prevKelompokMapel.current, kelompokMataPelajaranOpt));

      if(!isEqual(prevKelompokMapel.current, kelompokMataPelajaranOpt)){
        prevKelompokMapel.current = kelompokMataPelajaranOpt;

        handleSelectKelompokMataPelajaran(kelompokMataPelajaranOpt.find(e => e.label.includes(dataDetail.detail.kelompok_mata_pelajaran)))
      }

      if(!isEqual(prevJenisKelompokMapel.current, jenisKelompokMataPelajaranOpt)){
        prevJenisKelompokMapel.current = jenisKelompokMataPelajaranOpt;

        handleSelectJenisKelompokMataPelajaran(jenisKelompokMataPelajaranOpt.find(e => e.label.includes(dataDetail.detail.jenis_kelompok_mata_pelajaran)))
      }

      if(!isEqual(prevMapel.current, mapel)){
        prevMapel.current = mapel;
        handleSelectMataPelajaran(mapel.find(e => e.label.includes(dataDetail.detail.mata_pelajaran)))
      }



      if(!isEqual(prevJurusanValue.current, jurusan)){
        prevJurusanValue.current = jurusan;

        handleSelectJurusan(jurusan.find(e => e.value.includes(dataDetail.detail.jurusan)))
      }

      handleSelectSemester(semester.find(e => e.value == dataDetail.detail.semester))

      // handleSelectMataPelajaran(mapel.find(e => e.value == dataDetail.detail.mata_pelajaran))
      // setSelectedMataPelajaran(dataDetail.detail.mata_pelajaran)

      
    }
  }, [dataDetail,kurikulum, jenjang, kelas, kelompokMataPelajaranOpt, jenisKelompokMataPelajaranOpt, mapel, jurusan])
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="row">
          <div className="card">
            <div className="card-header">
              <div className="col-sm-8">
                <h4>{action == "add" ? "Tambah" : action == "update" ? "Ubah" : "Rincian"} Butir Soal</h4>
              </div>
            </div>
            <div className="card-body nm">
              <div className="row">
                <div className="col-sm-4 border-right">
                  <div className="m-1 mt-4 mb-5">
                    <h5 className="text text-primary">Pengaturan</h5>
                    <div className="row mt-4">
                      <div className="col-4">
                        <span className="text-primary mt-3">Tipe Soal</span>
                      </div>
                      <div className="col-8">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={typeSoal[0]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={typeSoal}
                          onChange={(e) => handleSelectTypeSoal(e)}
                          value={selectedTypeSoal}
                        />
                      </div>
                    </div>
                    {type == "pilihan-ganda" ? (
                      <div className="row mt-2">
                        <div className="col-4">
                          <span className="text-primary mt-3">Group</span>
                        </div>
                        <div className="col-8">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={groupSoal[0]}
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                            isClearable={isClearable}
                            isRtl={isRtl}
                            isSearchable={isSearchable}
                            name="color"
                            options={groupSoal}
                            onChange={handleGroupSoal}
                            value={selectedGroupSoal}
                          />
                        </div>
                      </div>
                    ) : null}
                    {type == "pilihan-ganda" || type == "sebab-akibat" ? (
                      <div className="row mt-2">
                        <div className="col-4">
                          <span className="text-primary mt-3">Opsi</span>
                        </div>
                        <div className="col-8">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={jumlahPg[0]}
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                            isClearable={isClearable}
                            isRtl={isRtl}
                            isSearchable={isSearchable}
                            name="color"
                            options={jumlahPg}
                            onChange={handleSelectJumlahPg}
                            value={selectedJumlahPg}
                          />
                        </div>
                      </div>
                    ) : null}
                    <div className="row mt-2">
                      <div className="col-4">
                        <span className="text-primary mt-3">Judul</span>
                      </div>
                      <div className="col-8">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Masukkann Judul"
                          onChange={(e) => setTitle(e.target.value)}
                          value={title}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-4">
                        <span className="text-primary mt-3">Kurikulum</span>
                      </div>
                      <div className="col-8">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={jenjang[0]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={kurikulum}
                          onChange={handleSelectKurikulum}
                          value={selectedKurikulum}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-4">
                        <span className="text-primary mt-3">Jenjang</span>
                      </div>
                      <div className="col-8">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={jenjang[0]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={jenjang}
                          onChange={handleSelectJenjang}
                          value={selectedJenjang}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-4">
                        <span className="text-primary mt-3">Kelompok Mata Pelajaran</span>
                      </div>
                      <div className="col-8">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={jenjang[0]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={kelompokMataPelajaranOpt}
                          onChange={handleSelectKelompokMataPelajaran}
                          value={selectedKelompokMataPelajaran}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-4">
                        <span className="text-primary mt-3">Jenis Kelompok Mata Pelajaran</span>
                      </div>
                      <div className="col-8">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={jenjang[0]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={jenisKelompokMataPelajaranOpt}
                          onChange={handleSelectJenisKelompokMataPelajaran}
                          value={selectedJenisKelompokMataPelajaran}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-4">
                        <span className="text-primary mt-3">
                          Mata Pelajaran
                        </span>
                      </div>
                      <div className="col-8">
                      {/* <input
                          type="text"
                          className="form-control"
                          placeholder="Masukkann Mata Pelajaran"
                          value={selectedMataPelajaran}
                          onChange={(e) => setSelectedMataPelajaran(e.target.value)}
                          disabled={isDisabled}
                        /> */}
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={mapel[0]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={mapel}
                          value={selectedMataPelajaran}
                          onChange={handleSelectMataPelajaran}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-4">
                        <span className="text-primary mt-3">Kelas</span>
                      </div>
                      <div className="col-8">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={kelas[0]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={kelas}
                          onChange={handleSelectKelas}
                          value={selectedKelas}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-4">
                        <span className="text-primary mt-3">Jurusan</span>
                      </div>
                      <div className="col-8">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={jurusan[0]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={jurusan}
                          onChange={handleSelectJurusan}
                          value={selectedJurusan}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-4">
                        <span className="text-primary mt-3">Semester</span>
                      </div>
                      <div className="col-8">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={semester[0]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={semester}
                          onChange={handleSelectSemester}
                          value={selectedSemester}
                        />
                      </div>
                    </div>
                    
                    <div className="row mt-2">
                      <div className="col-4">
                        <span className="text-primary mt-3">
                          Tingkat Kesuliatan
                        </span>
                      </div>
                      <div className="col-8">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={tingkatKesulitan[0]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={tingkatKesulitan}
                          onChange={handleSelectTingkatKesulitan}
                          value={selectedTingkatKesulitan}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-4">
                        <span className="text-primary mt-3">Indikator</span>
                      </div>
                      <div className="col-8">
                        <textarea
                          name=""
                          className="form-control"
                          id=""
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          disabled={isDisabled}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-8 custom-ckeditor">
                  <div className="m-1 mt-4">
                    {type == "pilihan-ganda" ? (
                      <PilihanGanda action={action} jumlahpg={selectedJumlahPg.value} groupsoal={selectedGroupSoal.value} onSaveButton={handleSaveButton} defaultValue={defaultValue} isDisabled={isDisabled} />
                    ) : type == "sebab-akibat" ? (
                      // <></>
                      <SebabAkibat action={action} jumlahpg={selectedJumlahPg.value} onSaveButton={handleSaveButton} defaultValue={defaultValue} isDisabled={isDisabled} />
                    ) : type == "uraian" ? (
                      // <></>
                      <Uraian action={action} onSaveButton={handleSaveButton} defaultValue={defaultValue} isDisabled={isDisabled}/>
                    ) : type == "penjodohan" ? (
                      // <></>
                      <Penjodohan action={action} onSaveButton={handleSaveButton} defaultValue={defaultValue} isDisabled={isDisabled}/>
                    ) : (
                      // <></>
                      <BenarSalah action={action} onSaveButton={handleSaveButton} defaultValue={defaultValue} isDisabled={isDisabled}/>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddButirSoal;
