import React, { useEffect, useRef, useState } from "react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { isEqual } from "../../../../utils";
import axiosInstance from "../../../../services/AxiosInstance";
import {
  ClassicEditor
} from 'ckeditor5';
import SMEditor from "../../Editor";
function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const file = await loader.file;
          console.log('loader file : ', file);
          const response = await axiosInstance.request({
            method: "POST",
            url: `media/upload`,
            data: {
              file: file
            },
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          console.log(response);
          resolve({
            default: `${response.data.url}`
          });
        } catch (error) {
          reject("Hello");
        }
      });
    },
    abort: () => {}
  };
}
function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    console.log("loader : ", loader);
    return uploadAdapter(loader);
  };
}
const Uraian = (props) => {
  const [soal, setSoal] = useState("");
  const [pembahasan, setPembahasan] = useState("");

  const prevSoalValue = useRef();
  useEffect(() => {
    console.log("Uraian props : ", props);
    if (props.defaultValue != null) {
      if(!isEqual(prevSoalValue.current, soal)){
        prevSoalValue.current = soal;
        setSoal(props.defaultValue.pertanyaan);
        setPembahasan(props.defaultValue.pembahasan);
      }
    }
  }, [props.defaultValue])
  const handleSaveButton = (redirect) => {
    var params = {
      "pertanyaan": soal,
      "pembahasan": pembahasan,
    };
    props.onSaveButton(params, redirect)

    if (redirect) {
      setSoal("");
      setPembahasan("");
    }
  };
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <h5 className="text text-primary">
            Input Soal <span className="text-danger">*</span>
          </h5>
          <div className="col-xl-12 mt-2">
            <SMEditor
              // config={{
              //   placeholder: "Tuliskan soal di sini...",
              //   extraPlugins: [uploadPlugin]
              // }}
              // editor={ClassicEditor}
              // onReady={(editor) => {}}
              placeholder="Tuliskan soal di sini..."
              data={soal}
              disabled={props.isDisabled}
              onChange={(event, editor) => {
                const data = editor.getData();
                setSoal(data);
              }}
              onBlur={(event, editor) => {}}
            />
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-sm-12">
          <hr />
          <h5 className="text text-primary">
            Pembahasan <span className="text-danger">*</span>
          </h5>
          <div className="col-xl-12 mt-2">
            <SMEditor
              // config={{
              //   placeholder: "Tuliskan pembahasan soal di sini...",
              //   extraPlugins: [uploadPlugin]
              // }}
              // editor={ClassicEditor}
              // onReady={(editor) => {}}
              placeholder="Tuliskan pembahasan soal di sini..."
              data={pembahasan}
              disabled={props.isDisabled}
              onChange={(event, editor) => {
                const data = editor.getData();
                setPembahasan(data);
              }}
              onBlur={(event, editor) => {}}
              // onFocus={(event, editor) => {}}
            />
          </div>
        </div>
      </div>
      {props.isDisabled ? "" : (
      <div className="row mt-3 mb-4">
        <div className="col-sm-12 text-end">
          {/* <button className="btn btn-sm m-1 btn-outline-primary">
            Simpan Draft
          </button> */}
          {/* <button className="btn btn-sm m-1 btn-primary" onClick={handleSaveButton}>Simpan</button> */}
          <button className="btn btn-sm m-1 btn-danger" onClick={() => handleSaveButton(false)}>Simpan & Keluar</button>
          <button className="btn btn-sm m-1 btn-primary" onClick={() => handleSaveButton(true)}>Simpan & Buat Baru</button>
        </div>
      </div>
      )}
    </>
  );
};

export default Uraian;
