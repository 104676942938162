import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axiosInstance from "../../../../services/AxiosInstance";
import {
  Badge,
  Modal,
  Button,
  Row,
  Col
} from "react-bootstrap";
import Swal from "sweetalert2";
import "./DetailHasil.css";

const DetailHasilTestOnline = (props) => {
  const inputEl = useRef(null);

  const { id_ujian } = useParams();

  const [data, setData] = useState(
    document.querySelectorAll("#job_data tbody tr")
  );

  const getLocalStorage = (key) => {
    const item = localStorage.getItem(key);
    return item ?? null;
  };

  const setLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
  };

  const [dataSoal, setDataSoal] = useState([
    { id: 1, tipe: "pilihan-ganda" },
    { id: 2, tipe: "pilihan-ganda" },
    { id: 3, tipe: "pilihan-ganda" },
    { id: 4, tipe: "pilihan-ganda" },
    { id: 5, tipe: "pilihan-ganda" },
    { id: 6, tipe: "pilihan-ganda" },
    { id: 7, tipe: "pilihan-ganda" },
    { id: 8, tipe: "pilihan-ganda" },
    { id: 9, tipe: "pilihan-ganda" },
    { id: 10, tipe: "pilihan-ganda" },
    { id: 11, tipe: "pilihan-ganda" },
    { id: 12, tipe: "pilihan-ganda" },
    { id: 13, tipe: "pilihan-ganda" },
    { id: 14, tipe: "pilihan-ganda" },
    { id: 15, tipe: "pilihan-ganda" },
    { id: 16, tipe: "pilihan-ganda" },
    { id: 17, tipe: "pilihan-ganda" },
    { id: 18, tipe: "pilihan-ganda" },
    { id: 19, tipe: "pilihan-ganda" },
    { id: 20, tipe: "pilihan-ganda" },
  ]);

  const [dataNilai, setDataNilai] = useState([
    {
      nama_peserta: "Ahmad Riyadi",
      nilai_total: 84,
      jawaban: [
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "A", result: false },
        { nilai: 4, jawaban: "B", result: true },
      ],
    },
    {
      nama_peserta: "Budi Santoso",
      nilai_total: 76,
      jawaban: [
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
      ],
    },
    {
      nama_peserta: "Citra Dewi",
      nilai_total: 88,
      jawaban: [
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
      ],
    },
    {
      nama_peserta: "Dian Lestari",
      nilai_total: 92,
      jawaban: [
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 0, jawaban: "A", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "D", result: true },
      ],
    },
    {
      nama_peserta: "Eka Putra",
      nilai_total: 80,
      jawaban: [
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "D", result: true },
      ],
    },
    // Tambahkan 15 data lainnya sesuai pola di atas
    {
      nama_peserta: "Farhan Hakim",
      nilai_total: 72,
      jawaban: [
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "B", result: true },
      ],
    },
    {
      nama_peserta: "Gilang Ramadhan",
      nilai_total: 78,
      jawaban: [
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "D", result: true },
      ],
    },
    {
      nama_peserta: "Hana Setiawan",
      nilai_total: 82,
      jawaban: [
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
      ],
    },
    {
      nama_peserta: "Ilham Pratama",
      nilai_total: 70,
      jawaban: [
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "A", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
      ],
    },
    {
      nama_peserta: "Joko Purwanto",
      nilai_total: 74,
      jawaban: [
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
      ],
    },
    {
      nama_peserta: "Kurniawan Saputra",
      nilai_total: 78,
      jawaban: [
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
      ],
    },
    {
      nama_peserta: "Linda Putri",
      nilai_total: 80,
      jawaban: [
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "A", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "A", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
      ],
    },
    {
      nama_peserta: "Maya Sari",
      nilai_total: 88,
      jawaban: [
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "A", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
      ],
    },
    {
      nama_peserta: "Nadia Maulida",
      nilai_total: 76,
      jawaban: [
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "D", result: false },
      ],
    },
    {
      nama_peserta: "Omar Sulaiman",
      nilai_total: 86,
      jawaban: [
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
      ],
    },
  ]);

  const sort = 100;
  const activePag = useRef(0);

  const [showPage, setShowPage] = useState(
    getLocalStorage("showPage") ?? "nilai"
  );

  const [showKomposisi, setShowKomposisi] = useState(false);

  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };

  const handleShow = (param) => {
    setLocalStorage("showPage", param);
    setShowPage(param);
  };
  
  const handleShowKomposisi = () => {
    setShowKomposisi(!showKomposisi);
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#job_data tbody tr"));
  }, []);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    //settest(i);
  };



  //LOAD DATA
  const [studentsData, setStudentsData] = useState(null);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [showKoreksiModal, setShowKoreksiModal] = useState(false);
  useEffect(() => {
      loadDataJawaban()
  }, [])
  const loadDataJawaban = async () => {
    var res = await axiosInstance.get(`ujian/${id_ujian}`);
      console.log(res);
      var data = res.data.data;
      loadPaketSoalDetail(data.paket_soal_id)
      var tmp = []
      for(var i = 0; i < data.peserta.length; i++){
        var response = []
        for(var a = 0; a < data.peserta[i].value.length; a++){
            response.push(data.peserta[i].value[a])
        }
        tmp.push(
            {
                id: data.peserta[i].id,
                kelas: data.peserta[i].detail.kelas,
                rombel: data.peserta[i].detail.rombel,
                nama_siswa: data.peserta[i].user.name,
                status: data.peserta[i].status,
                responses: response,
                score: data.peserta[i].score,
                nilai_bobot: data.peserta[i].nilai_bobot,
                presentase_nilai_bobot: data.peserta[i].presentase_nilai_bobot,
                ranking: data.peserta[i].ranking
        })
      }

      setNumberOfQuestions(tmp[0]?.responses.length || 0)

      console.log(tmp)

      setStudentsData(tmp)
  }

  const [selectedPaketSoal, setSelectedPaketSoal] = useState(null);
  const loadPaketSoalDetail = async (id_paket_soal) => {
    var res = await axiosInstance.get(`paket-soal/${id_paket_soal}?with_content=true`);
    console.log('res : ', res)
    setSelectedPaketSoal(res.data.data)


    // Object.entries(selectedPaketSoal.score).map(([key, value]) => {
      
    // })
  }

  const [selectedResponse, setSelectedResponse] = useState(null);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const handleShowKoreksiModal = (studentId, response) => {
    setSelectedStudentId(studentId);
    setSelectedResponse(response);
    setShowKoreksiModal(true);
  };

  const handleCloseKoreksiModal = () => {
    setSelectedStudentId(null);
    setSelectedResponse(null);
    setShowKoreksiModal(false);
  };

  const handleKirimKoreksi = async (result, code) => {
    try {
      console.log("Kirim koreksi", result, code);
      var params = {
        "code": code,
        "result": result
      }
      var res = await axiosInstance.put(`test-online/guru/correcting-answer/${selectedStudentId}`, params);
      
      handleCloseKoreksiModal();
      loadDataJawaban();

      Swal.fire({
        icon: 'success',
        title: 'Koreksi Berhasil',
        showConfirmButton: true,
      })
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Koreksi Gagal',
        text: error.message,
        showConfirmButton: true,
      })
    }
    
  };

  const getTypeCode = (type) => {
    if (type == "PG") {
      return "Pilihan Ganda";
    }else if (type == "MPG") {
      return "Multiple Pilihan Ganda";
    } else if (type == "BS") {
      return "Benar Salah";
    } else if (type == "UR") {
      return "Uraian";
    } else if (type == "PJ") {
      return "Penjodohan";
    } else if (type == "SA") {
      return "Sebab Akibat";
    }
  };

  const [nilaiValue, setNilaiValue] = useState(5);
  
  const handleChangeNilai = (e) => {
    setNilaiValue(e.target.value);
  };

  const handleExportToExcel = async (tipe) => {
    

    try {
      var urlAPI = ''

      if (tipe == "nilai") {
        //ujian/export/jawaban/id ujian
        urlAPI = `ujian/export/jawaban/${id_ujian}`
      }


      if (tipe == "rekap") {
        //ujian/export/hasil-ujian/id ujian
        urlAPI = `ujian/export/hasil-ujian/${id_ujian}`
      }
      
      // Meminta file Excel dari backend
      const response = await axiosInstance.get(urlAPI, {
        responseType: 'arraybuffer', // Pastikan response diterima dalam format arraybuffer
      });
  
      // Membuat Blob dari data response
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
      // Membuat URL object untuk file Blob
      const url = window.URL.createObjectURL(blob);
  
      // Membuat elemen anchor untuk memicu unduhan
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', tipe + "_" + id_ujian +'.xlsx'); // Nama file yang akan diunduh
      document.body.appendChild(link);
  
      // Memicu klik untuk mengunduh file
      link.click();
  
      // Membersihkan URL object setelah digunakan
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading Excel file', error);
    }
  };

  const [isAscending, setIsAscending] = useState(true);

  const handleSort = (type) => {
    if (type == "nilai") {
      const sorted = [...studentsData].sort((a, b) =>
        isAscending ? a.nilai_bobot - b.nilai_bobot : b.nilai_bobot - a.nilai_bobot
      );
      setStudentsData(sorted);
      setIsAscending(!isAscending);
    } else if (type == "nilai_persen") {
      const sorted = [...studentsData].sort((a, b) =>
        isAscending ? a.presentase_nilai_bobot - b.presentase_nilai_bobot : b.presentase_nilai_bobot - a.presentase_nilai_bobot
      );
      setStudentsData(sorted);
      setIsAscending(!isAscending);
    } else if (type == "ranking") {
      const sorted = [...studentsData].sort((a, b) =>
        isAscending ? a.ranking - b.ranking : b.ranking - a.ranking
      );
      setStudentsData(sorted);
      setIsAscending(!isAscending);
    };
  };
  return (
    <div className="col-12">
      <div className="card">
        <div onClick={handleShowKomposisi} className="card-header card-header-komposisi d-flex justify-content-between flex-wrap">
          <div>
            <div className="card-title">Komposisi Soal</div>
          </div>
          <div>
            <span>
              <i className={`fas fa-chevron-${showKomposisi ? 'up' : 'down'}`}></i>
            </span>
          </div>
        </div>
        {showKomposisi ? (<div className="card-body">
          <div className="row">
            <div className="col-4">
              <table className="w-100 nm">
                <tr>
                  <td className="p-2 text-dark">
                    <b>Perangkai</b>
                  </td>
                  <td className="p-2 text-dark">
                    <b>: Donawariska</b>
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-dark">
                    <b>Kode Ujian</b>
                  </td>
                  <td className="p-2 text-dark">
                    <b>: U - 123456</b>
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-dark">
                    <b>Jenjang</b>
                  </td>
                  <td className="p-2 text-dark">
                    <b>: SMA / MA</b>
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-dark">
                    <b>Kelas</b>
                  </td>
                  <td className="p-2 text-dark">
                    <b>: X</b>
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-dark">
                    <b>Jurusan</b>
                  </td>
                  <td className="p-2 text-dark">
                    <b>: MIPA</b>
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-dark">
                    <b>Mata Pelajaran</b>
                  </td>
                  <td className="p-2 text-dark">
                    <b>: Bahasa Indonesia</b>
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-dark">
                    <b>Nama Paket Ujian</b>
                  </td>
                  <td className="p-2 text-dark">
                    <b>: UTS Bahasa Indonesia</b>
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-dark">
                    <b>Durasi</b>
                  </td>
                  <td className="p-2 text-dark">
                    <b>: 120 (menit)</b>
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-dark">
                    <b>Platform</b>
                  </td>
                  <td className="p-2 text-dark">
                    <b>: Test Online</b>
                  </td>
                </tr>
              </table>
            </div>
            <div className="col-8">
              <div className="table">
                <div id="job_data" className="dataTables_wrapper">
                  <table
                    className="display w-100 dataTable table-bordered"
                    id="example5"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th>Bentuk Soal</th>
                        <th>Jumlah Soal</th>
                        <th>Bobot</th>
                        <th>Benar</th>
                        <th>Salah</th>
                        <th>Tidak Jawab</th>
                      </tr>
                    </thead>

                    <tbody>
                      {selectedPaketSoal && Object.entries(selectedPaketSoal.score).map(([key, value]) => (
                        <tr>
                          <td>{getTypeCode(key)}</td>
                          <td>{value.jumlah_soal}</td>
                          <td>{value.bobot}</td>
                          <td>{value.point_benar}</td>
                          <td>{value.point_salah}</td>
                          <td>{value.point_kosong}</td>
                        </tr>
                      ))}
                      <tr>
                        <td>Jumlah Soal</td>
                        <td>50</td>
                        <td colSpan={5}>100</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>) : null}
      </div>
      <div className="card dz-card">
        <div className="card-header d-flex justify-content-between flex-wrap">
          <div>
            <div className="card-title">Hasil Ujian</div>
          </div>
          <ul
            as="ul"
            className="nav nav-tabs dzm-tabs"
            id="myTab"
            role="tablist"
          >
            <li as="li" className="nav-item">
              <button
                className={`nav-link ${showPage == "nilai" ? "active" : ""}`}
                type="button"
                onClick={() => handleShow("nilai")}
              >
                Hasil Jawaban Siswa
              </button>
            </li>
            <li as="li" className="nav-item" role="presentation">
              <button
                className={`nav-link ${showPage == "jawaban" ? "active" : ""}`}
                type="button"
                onClick={() => handleShow("jawaban")}
              >
                Nilai Per Butir
              </button>
            </li>
            <li as="li" className="nav-item" role="presentation">
              <button
                className={`nav-link ${showPage == "bobot" ? "active" : ""}`}
                type="button"
                onClick={() => handleShow("bobot")}
              >
                Hasil Nilai Bobot
              </button>
            </li>
            <li as="li" className="nav-item" role="presentation">
              <button
                className={`nav-link ${showPage == "bobot_persen" ? "active" : ""}`}
                type="button"
                onClick={() => handleShow("bobot_persen")}
              >
                Hasil Nilai Persen
              </button>
            </li>
            <li as="li" className="nav-item" role="presentation">
              <button
                className={`nav-link ${showPage == "rekap" ? "active" : ""}`}
                type="button"
                onClick={() => handleShow("rekap")}
              >
                Rekap Hasil Nilai
              </button>
            </li>
            
          </ul>
        </div>
        {showPage == "nilai" && <button className="btn btn-primary" onClick={() => handleExportToExcel('nilai')}>Export to Excel</button>}
        {showPage == "rekap" && <button className="btn btn-primary" onClick={() => handleExportToExcel('rekap')}>Export to Excel</button>}
        {showPage == "nilai" && <div className="card-body">
          <div className="table-responsive">
            <div id="job_data" className="dataTables_wrapper">
              <table
                className="display w-100 dataTable table-bordered"
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th className="sorting_asc ">No</th>
                    <th className="sorting ">Kelas</th>
                    <th className="sorting ">Rombel</th>
                    <th className="sorting ">Nama Peserta</th>
                    {/* <th className="text-center ">Nilai Akhir</th> */}
                    {Array.from({length: numberOfQuestions}, (_, index) => (
                      <th key={index}>{index + 1}</th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                {studentsData?.map((student, index) => (
                    <tr key={index + 1}>
                        <td>
                            <strong>{index + 1}</strong>
                        </td>
                        <td>{student.kelas}</td>
                        <td>{student.rombel}</td>
                        <td>{student.nama_siswa}</td>
                        {/* Render responses for each question */}
                        {/* {JSON.stringify(student.responses[0])} */}
                        {student.responses?.map((response, index) => (
                            <td key={index}>
                                {/* {typeof response.jawaban} */}
                                {/* {response.jawaban == null ? 'null' : 'ada'} */}
                                {response.jawaban != null ? (
                                    <Badge onClick={response.type == "UR" ? () => handleShowKoreksiModal(student.id, response) : null} as="a" bg="badge-lg" bg={response.type == "UR" && response.status == null  ? "warning" : response.type == "UR" && response.status != null ? "success" : response.jawaban == "ragu" ? "warning" : response.status == "benar" ? "success" : "danger"}>
                                       {response.type == "UR" && response.status == null ? <i className="fas fa-eye"></i> : response.type == "UR" && response.status != null ? "Sudah dikoreksi" : response.jawaban}
                                    </Badge>
                                    ) : (
                                        ""
                                )}
                            </td>
                        ))}
                        <td>
                            {student.status == "selesai" ? (
                                <Badge bg="success">{student.status}</Badge>
                            ) : (
                                <Badge bg="danger">{student.status}</Badge>
                            )}
                        </td>
                    </tr>
                ))}
                  {/* {dataNilai.map((v, i) => (
                    <tr>
                      <td className="">{i + 1}</td>
                      <td className="">{id_ujian}</td>
                      <td className="">{v.nama_peserta}</td>
                      <td className="text-center ">
                        <b className="text-dark">{v.nilai_total}</b>
                      </td>
                      {dataSoal.map((value, index) => (
                        <td>
                          <span
                            className={`badge badge-sm ${
                              v.jawaban[index].result
                                ? "bg-success"
                                : "bg-danger"
                            }`}
                          >
                            {showPage == "jawaban"
                              ? v.jawaban[index].jawaban
                              : v.jawaban[index].nilai}
                          </span>{" "}
                        </td>
                      ))}
                    </tr>
                  ))} */}
                </tbody>
              </table>
              {/* <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                <div className="dataTables_info">
                  Showing {activePag.current * sort + 1} to{" "}
                  {data.length > (activePag.current + 1) * sort
                    ? (activePag.current + 1) * sort
                    : data.length}{" "}
                  of {data.length} entries
                </div>
                {data.length > sort ? (
                  <div
                    className="dataTables_paginate paging_simple_numbers mb-0"
                    id="example5_paginate"
                  >
                    <Link
                      className="paginate_button previous disabled"
                      to={`/hasil-ujian/detail/${id_ujian}`}
                      onClick={() =>
                        activePag.current > 0 && onClick(activePag.curreU - 1)
                      }
                    >
                      <i className="fa fa-angle-left" aria-hidden="true"></i>
                    </Link>
                    <span>
                      {paggination.map((number, i) => (
                        <Link
                          key={i}
                          to={`/hasil-ujian/detail/${id_ujian}`}
                          className={`paginate_button  ${
                            activePag.current === i ? "current" : ""
                          } `}
                          onClick={() => onClick(i)}
                        >
                          {number}
                        </Link>
                      ))}
                    </span>
                    <Link
                      className="paginate_button next"
                      to={`/hasil-ujian/detail/${id_ujian}`}
                      onClick={() =>
                        activePag.current + 1 < paggination.length &&
                        onClick(activePag.current + 1)
                      }
                    >
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </Link>
                  </div>
                ) : null}
              </div> */}
            </div>
          </div>
        </div>
        }

        {(showPage == "jawaban" || showPage == "bobot" || showPage == "bobot_persen" || showPage == "rekap") && <div className="card-body">
          <div className="table-responsive">
            <div id="job_data" className="dataTables_wrapper">
              <table
                className="display w-100 dataTable table-bordered"
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th className="sorting_asc">No</th>
                    <th className="sorting ">Kelas</th>
                    <th className="sorting ">Rombel</th>
                    <th className="sorting ">Nama Peserta</th>
                    {/* <th className="text-center ">Nilai Akhir</th> */}
                    {Array.from({length: numberOfQuestions}, (_, index) => (
                      <th key={index}>{index + 1}</th>
                    ))}
                    {(showPage == "bobot" || showPage == "bobot_persen") && <th className="sorting ">Nilai Akhir</th>}
                    {showPage == "rekap" && <th className="sorting " onClick={() => {
                      handleSort("nilai")
                    }}>Nilai</th>}
                    {showPage == "rekap" && <th className="sorting " onClick={() => {
                      handleSort("nilai_persen")
                    }}>Nilai %</th>}
                    {showPage == "rekap" && <th className="sorting " onClick={() => {
                      handleSort("ranking")
                    }}>Ranking</th>}
                  </tr>
                </thead>

                <tbody>
                {studentsData?.map((student, index) => (
                    <tr key={index + 1}>
                        <td>
                            <strong>{index + 1}</strong>
                        </td>
                        <td>{student.kelas}</td>
                        <td>{student.rombel}</td>
                        <td>{student.nama_siswa}</td>
                        {/* Render responses for each question */}
                        {/* {JSON.stringify(student.responses[0])} */}
                        {student.responses?.map((response, index) => (
                            <td key={index}>
                                {/* {typeof response.jawaban} */}
                                {/* {response.jawaban == null ? 'null' : 'ada'} */}
                                {/* {response.status == null ? response.point.kosong
                                : response.status == "benar" ? response.point.benar : response.point.salah} */}
                                {parseFloat(showPage == "jawaban" || showPage == "rekap" ?
                                response.nilai :
                                showPage == "bobot" ? response.nilai_bobot : response.presentase_nilai_bobot).toFixed(2)}
                                &nbsp;
                                {(showPage == "jawaban" && response.type == "UR") && <i className="fa fa-pencil" aria-hidden="true" onClick={() => handleShowKoreksiModal(student.id, response)}></i>}
                            </td>
                        ))}
                        {(showPage == "bobot" || showPage == "bobot_persen") && <td>
                            {parseFloat(showPage == "bobot" ? student.nilai_bobot : student.presentase_nilai_bobot).toFixed(2)}
                        </td>
                        }
                        {(showPage == "rekap") && <td>
                            {parseFloat(student.nilai_bobot).toFixed(2)}
                        </td>
                        }
                        {(showPage == "rekap") && <td>
                            {parseFloat(student.presentase_nilai_bobot).toFixed(2)}
                        </td>
                        }
                        {(showPage == "rekap") && <td> {student.ranking} </td>}
                    </tr>
                ))}
                  {/* {dataNilai.map((v, i) => (
                    <tr>
                      <td className="">{i + 1}</td>
                      <td className="">{id_ujian}</td>
                      <td className="">{v.nama_peserta}</td>
                      <td className="text-center ">
                        <b className="text-dark">{v.nilai_total}</b>
                      </td>
                      {dataSoal.map((value, index) => (
                        <td>
                          <span
                            className={`badge badge-sm ${
                              v.jawaban[index].result
                                ? "bg-success"
                                : "bg-danger"
                            }`}
                          >
                            {showPage == "jawaban"
                              ? v.jawaban[index].jawaban
                              : v.jawaban[index].nilai}
                          </span>{" "}
                        </td>
                      ))}
                    </tr>
                  ))} */}
                </tbody>
              </table>
              {/* <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                <div className="dataTables_info">
                  Showing {activePag.current * sort + 1} to{" "}
                  {data.length > (activePag.current + 1) * sort
                    ? (activePag.current + 1) * sort
                    : data.length}{" "}
                  of {data.length} entries
                </div>
                {data.length > sort ? (
                  <div
                    className="dataTables_paginate paging_simple_numbers mb-0"
                    id="example5_paginate"
                  >
                    <Link
                      className="paginate_button previous disabled"
                      to={`/hasil-ujian/detail/${id_ujian}`}
                      onClick={() =>
                        activePag.current > 0 && onClick(activePag.curreU - 1)
                      }
                    >
                      <i className="fa fa-angle-left" aria-hidden="true"></i>
                    </Link>
                    <span>
                      {paggination.map((number, i) => (
                        <Link
                          key={i}
                          to={`/hasil-ujian/detail/${id_ujian}`}
                          className={`paginate_button  ${
                            activePag.current === i ? "current" : ""
                          } `}
                          onClick={() => onClick(i)}
                        >
                          {number}
                        </Link>
                      ))}
                    </span>
                    <Link
                      className="paginate_button next"
                      to={`/hasil-ujian/detail/${id_ujian}`}
                      onClick={() =>
                        activePag.current + 1 < paggination.length &&
                        onClick(activePag.current + 1)
                      }
                    >
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </Link>
                  </div>
                ) : null}
              </div> */}
            </div>
          </div>
        </div>
        }
      </div>
      <Modal className="fade bd-example-modal-lg" size="md" show={showKoreksiModal}>
			  <Modal.Header>
				<Modal.Title>{showPage == 'nilai' ? 'Jawaban' : 'Koreksi Jawaban'}</Modal.Title>
				<Button
				  variant=""
				  className="btn-close"
				  onClick={handleCloseKoreksiModal}
				>
				  
				</Button>
			  </Modal.Header>
			  <Modal.Body>
          <Row>
            <Col lg={3}>Kunci</Col>
            <Col style={{display: 'inline-flex'}}>: &nbsp; {selectedPaketSoal && selectedResponse && <span dangerouslySetInnerHTML={{__html: selectedPaketSoal.value.filter((v) => v.code == selectedResponse.code)[0].value.pembahasan ?? "Tidak ada kunci"}}></span>}</Col>
          </Row>
          <Row>
            <Col lg={3}>Jawaban</Col>
            {selectedResponse?.jawaban == null && <Col>: Tidak ada Jawaban</Col>}
            {selectedResponse?.jawaban != null && <Col style={{display: 'inline-flex'}}>: &nbsp; <span dangerouslySetInnerHTML={{__html: selectedResponse?.jawaban}}></span></Col>}
          </Row>
          {showPage == 'jawaban' && <Row>
            <Col lg={3}>Nilai</Col>
            <Col>: {nilaiValue}</Col>
          </Row>}
          {showPage == 'jawaban' && <Row>
            <Col><input
                type="range"
                className="form-range"
                id="rangeInput"
                min="0"
                max="10"
                value={nilaiValue}
                onChange={handleChangeNilai}
              /></Col>
          </Row>
          }
        </Modal.Body>
			  {showPage == 'jawaban' && <Modal.Footer>
				{/* <Button variant="danger" onClick={()=>handleKirimKoreksi(nilaiValue, selectedResponse.code)}>Salah</Button> */}
				<Button variant="primary" onClick={()=>handleKirimKoreksi(nilaiValue, selectedResponse.code)}>Simpan</Button>
			  </Modal.Footer>}
			</Modal>
    </div>
  );
};

export default DetailHasilTestOnline;
