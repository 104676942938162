import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import Preview from "../DaftarUjian/Steps/Preview";
import axiosInstance from "../../../services/AxiosInstance";
import './preview.css';
const PreviewPaketSoal = () => {
  const { id_paket } = useParams();
  const [soal, setSoal] = useState([]);

  const [bentukSoal, setBentukSoal] = useState([]);

  const [totalSoal, setTotalSoal] = useState(0);
  const [totalBobot, setTotalBobot] = useState(0);

  const [detailPaket, setDetailPaket] = useState(null);

  const getTypeName = (type) => {
    if (type == "PG") {
      return "pilihan-ganda";
    } else if (type == "MPG") {
      return "multiple-pilihan-ganda";
    } else if (type == "BS") {
      return "benar-salah";
    } else if (type == "UR") {
      return "uraian";
    } else if (type == "PJ") {
      return "penjodohan";
    } else if (type == "SA") {
      return "sebab-akibat";
    }
  };

  const getDataById = async () => {
    var res = await axiosInstance.get(`paket-soal/${id_paket}?with_content=true`);
    var paketSoal = res.data.data.value;

    setDetailPaket(res.data.data);
    var tmp = []
    for (var i = 0; i < paketSoal.length; i++) {
      // var opt = [];
      // if (paketSoal[i].type != "UR" && paketSoal[i].type != "MPG") {
      //   Object.entries(paketSoal[i].value.pilihan).forEach(([key, value]) => {
      //     var result = false;
      //     if (key == paketSoal[i].value.jawaban) {
      //       result = true;
      //     }
      //     opt.push({ result: result, value: value });
      //   })
      // }
      tmp.push({
        type: getTypeName(paketSoal[i].type),
        ...paketSoal[i]
      })
      
      // tmp.push({
      //   type: getTypeName(paketSoal[i].type),
      //   soal: paketSoal[i].value.pertanyaan,
      //   option: opt
      // })

      const scoreArray = Object.entries(res.data.data.score).map(([key, value]) => ({
        type: getTypeName(key),
        ...value
      }));

      const totals = scoreArray.reduce((acc, curr) => {
        acc.jumlah_soal += Number(curr.jumlah_soal); // Convert to number to avoid string addition
        acc.bobot += Number(curr.bobot);
        return acc;
    }, { jumlah_soal: 0, bobot: 0 });

      setBentukSoal(scoreArray);

      setTotalSoal(totals.jumlah_soal);
      setTotalBobot(totals.bobot);

      console.log("scoreArray:", scoreArray);
    }

    console.log("tmp:", tmp);
    setSoal(tmp);
  };

  useEffect(() => {
    getDataById();
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header card-header-komposisi d-flex justify-content-between flex-wrap">
              <div>
                <div className="card-title">Komposisi Soal</div>
              </div>
              <div>
                <span>
                  <i className={`fas fa-chevron-down`}></i>
                </span>
              </div>
            </div>
            { detailPaket == null ? "" : (
              
            
            <div className="card-body">
              <div className="row">
                <div className="col-4">
                  <table className="w-100 nm">
                    <tr>
                      <td className="p-2 text-dark">
                        <b>Perangkai</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: Donawariska</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 text-dark">
                        <b>Kode Ujian</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {detailPaket.code}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 text-dark">
                        <b>Jenjang</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {detailPaket.detail.jenjang}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 text-dark">
                        <b>Kelas</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {detailPaket.detail.kelas}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 text-dark">
                        <b>Jurusan</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {detailPaket.detail.jurusan}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 text-dark">
                        <b>Mata Pelajaran</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {detailPaket.detail.mata_pelajaran}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 text-dark">
                        <b>Nama Paket Ujian</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {detailPaket.detail.judul}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 text-dark">
                        <b>Durasi</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {detailPaket.detail.durasi / 60} (menit)</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 text-dark">
                        <b>Platform</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {detailPaket.type}</b>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="col-8">
                  <div className="table">
                    <div id="job_data" className="dataTables_wrapper">
                      <table
                        className="display w-100 dataTable table-bordered"
                        id="example5"
                        role="grid"
                        aria-describedby="example5_info"
                      >
                        <thead>
                          <tr role="row">
                            <th>Bentuk Soal</th>
                            <th>Jumlah Soal</th>
                            <th>Bobot</th>
                            <th>Benar</th>
                            <th>Salah</th>
                            <th>Tidak Jawab</th>
                          </tr>
                        </thead>

                        <tbody>
                          {bentukSoal.map((v, i) => (
                            <tr key={i}>
                              <td>{v.type}</td>
                              <td>{v.jumlah_soal}</td>
                              <td>{v.bobot}</td>
                              <td>{v.point_benar}</td>
                              <td>{v.point_salah}</td>
                              <td>{v.point_kosong}</td>
                            </tr>
                          ))}

                          <tr>
                            <td>Jumlah Soal</td>
                            <td>{totalSoal}</td>
                            <td colSpan={5}>{totalBobot}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 mt-4">
                  <div className="row">
                    <div className="col-5">
                      {" "}
                      <hr />
                    </div>
                    <div className="col-2 text-center">
                      <h5 className="text-primary mt-1">Preview Paket Soal</h5>
                    </div>
                    <div className="col-5">
                      {" "}
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <section>
                    {soal.map((v, i) => (
                      <div key={i}>
                        {/* Petunjuk untuk soal pilihan ganda hanya pada soal pilihan ganda pertama */}
                        {i ===
                          soal.findIndex(
                            (item) => item.type === "PG"
                          ) &&
                          (v.type === "PG") && (
                            <>
                              <b>I. Bentuk Soal Pilihan Ganda</b>
                              <p>
                                Pilihlah salah satu jawaban yang dianggap benar!
                              </p>
                            </>
                          )}

                        {/* Tampilkan soal pilihan ganda */}
                        {v.type === "PG" || v.type === "SA" || v.type === "PJ" || v.type === "BS" ? (
                          <div className="row mt-3">
                            <div className="col-1 text-center">
                              <b>{i + 1}.</b>
                            </div>
                            <div className="col-11">
                              <div className="col-soal mb-3">
                                <div dangerouslySetInnerHTML={{__html: v.value.pertanyaan}}></div>
                              </div>
                              {Object.keys(v.value.pilihan).map((val, index) => (
                                <div
                                  className="col-option d-flex mt-2"
                                  key={index}
                                >
                                  <input
                                    type="radio"
                                    className="me-3"
                                    readOnly
                                    checked={v.value.jawaban == val}
                                  />
                                  <div dangerouslySetInnerHTML={{__html: v.value.pilihan[val]}}></div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : ""}

                        {v.type === "MPG" && (
                          <>
                          <div className="row mt-2">
                            <div className="col-1 text-center">
                              <b></b>
                            </div>
                            <div className="col-11">
                              <div className="col-soal mb-3">
                                <div dangerouslySetInnerHTML={{__html: v.value.content}}></div>
                              </div>
                            </div>
                          </div>
                          { v.value.soal.map((soal, soalIndex) => (
                            <div className="row mt-2" key={soalIndex}>
                              <div className="col-1 text-center">
                                <b>{i + 1}.</b>
                              </div>
                              <div className="col-11">
                                <div className="col-soal mb-3">
                                  <div dangerouslySetInnerHTML={{__html: soal.pertanyaan}}></div>
                                </div>
                                {Object.keys(soal.pilihan).map((val, index) => (
                                  <div
                                    className="col-option d-flex mt-2"
                                    key={index}
                                  >
                                    <input
                                      type="radio"
                                      className="me-3"
                                      readOnly
                                      checked={soal.jawaban == val}
                                    />
                                    <div dangerouslySetInnerHTML={{__html: soal.pilihan[val]}}></div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                          
                          </>
                        )}

                        {i ===
                          soal.findIndex((item) => item.type === "UR") &&
                          v.type === "UR" && (
                            <div className="row mt-3">
                              <div className="col-sm-12">
                                <div className="mt-5">
                                  <b>II. Bentuk Soal Uraian</b>
                                  <p>Jawablah pertanyaan di bawah ini!</p>
                                </div>
                              </div>
                            </div>
                          )}

                        {v.type === "UR" && (
                          <div className="row mt-2">
                            <div className="col-1 text-center">
                              <b>{i + 1}.</b>
                            </div>
                            <div className="col-11">
                              <div className="col-soal mb-3">
                                <div dangerouslySetInnerHTML={{__html: v.value.pertanyaan}}></div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                    <div className="row">
                      <div className="col-12">
                        <hr />
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewPaketSoal;
